import React, { useEffect, useRef, useState } from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Button, Card } from '@shopify/polaris'
import useLang from 'lib/hooks/useLang'
import { serviceNames } from 'lib/const/humanReadableNames'
import { oauthURLs } from 'lib/const/oauthURLs'
import {useRecoilValue} from 'recoil'
import * as atoms from 'lib/atoms'
import useRemoteRedirect from 'lib/hooks/useRemoteRedirect'

// import GoogleSigninLightNormalWeb from 'images/google/x2web/btn_google_signin_light_normal_web@2x.png'
// import GoogleSigninLightFocusWeb from 'images/google/x2web/btn_google_signin_light_focus_web@2x.png'

// service, url, isFocus, isPressed
type ServiceBtnParams = {
  url: string
  normalBtn: JSX.Element
  pressedBtn: JSX.Element
}
const ServiceBtnWrapper = (props:ServiceBtnParams) => {
  const {
    url,
    normalBtn,
    pressedBtn,
  } = props
  const { lang } = useLang()
  const [ pressed, setPressed ] = useState(false)
  const { app, remoteRedirect } = useRemoteRedirect()
  const handleClick = () => {
    setPressed(true)
    if (!app) {
      window.location.replace(url)
      return
    }
    remoteRedirect(url)
  }


  return (
    <button onClick={handleClick} type='button' className='p-6'>
      { pressed ? pressedBtn : normalBtn }
    </button>
  )
}

export default ServiceBtnWrapper
