import React from 'react'
import { useRecoilValue } from 'recoil'
import * as atoms from 'lib/atoms'
import TopCard from './TopCard'
import DataCard from '../DataCard'
import TreeMap from '../Charts/TreeMap'
import useLang from 'lib/hooks/useLang'
import useDashboardData from 'lib/hooks/useDashboardData'
import { formatPercent, numberWithCommas } from 'lib/method/formatNumber'
import gaIcon from 'images/dashboard/grayGA.svg'
import { Tooltip } from '@shopify/polaris'
import { Div } from 'components/ui'
import { Spinner } from '@shopify/polaris'


const TopDesireGroup = () => {
  const loadingData = useRecoilValue(atoms.loadingData)
  const { translate } = useLang()

  const engagementsRate = translate('Marketing.engagmentRate')
  const engagements = translate('Marketing.engagements')
  const sessions = translate('Marketing.sessions')

  const {
    gaLoadingProgress,
    gaChannelsProgress,
    desireData,
    calcEngagementsBreakdown,
    calcEngagementChangeBreakdown,
  } = useDashboardData()

  const getContent = (data:any) => {
    const def = `${engagementsRate} = ${engagements} / ${sessions}`
    const desc = `${numberWithCommas(data.rateNum)} / ${numberWithCommas(data.rateDen)} = ${formatPercent(data.rate*100)}`
    const chDesc = `${numberWithCommas(data.last.rateNum)} / ${numberWithCommas(data.last.rateDen)} = ${formatPercent(data.last.rate*100)}`
    return (<div>
      <p><b>{def}</b></p>
      <hr className='mt-2' />
      <p><b>This Period:</b> {desc}</p>
      <hr />
      <p><b>Last Period:</b> {chDesc}</p>
    </div>)
  }
  // const getContent = () => {
  //   return `${engagementsRate} => ${engagements} / ${sessions} * 100 => ${gDesireTotal()} / ${(gaTotal==null) ? 0 : gaTotal.sessions} * 100 = ${formatPercent(gDesireRate())}`
  // }

  return (
    <div className='cardTop'>
      <div className='topCardStyle rounded-t-2xl pb-3'>
        <TopCard
          loadProgress={gaLoadingProgress()}
          metric='Desire'
          value={desireData.value}
          changeValue={desireData.changePercent.value}
          dataPercentage={formatPercent(desireData.changePercent.value*100)}
        />
      </div>
      <div className='lg:w-full 2xl:w-auto'>
        <Tooltip content={getContent(desireData)}>
          <DataCard
            dataTitle={engagementsRate}
            changeValue={desireData.change.rate}
            positivity={true}
            dataNumber={formatPercent(desireData.rate*100)}
            dataPercentage={formatPercent(desireData.change.rate*100)}
          />
        </Tooltip>
      </div>
      <div className={gaChannelsProgress() ? 'topCardStyle pt-7': 'topCardStyle pt-7 pb-4'}>
        <TreeMap data={calcEngagementsBreakdown()} metric='Desire' />
      </div>
      { gaChannelsProgress()
        ? (
          <Div className='flex justify-center items-center py-24'>
            <Spinner accessibilityLabel='google channel data is loading...' size='small' />
          </Div>
        )
        : calcEngagementChangeBreakdown().map(ch => (
          <DataCard
            key={ch.id}
            loading={gaChannelsProgress()}
            dataTitle={ch.id}
            changeValue={ch.change}
            dataNumber={formatPercent(ch.value)}
            dataPercentage={formatPercent(ch.change)}
            publisherLogo={gaIcon}
          />
        ))}
    </div>
  )
}

export default TopDesireGroup
