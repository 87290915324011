import React, { useCallback, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { Lang } from '@sh/enums'
import * as atoms from 'lib/atoms'
import { Screen } from 'lib/enum'
import useLang from 'lib/hooks/useLang'
import { StaticImage } from 'gatsby-plugin-image'
import { SettingsMajor, MobileHorizontalDotsMajor, CalendarMinor, PageMajor } from '@shopify/polaris-icons'
import { ActionList, Icon, Popover } from '@shopify/polaris'
import SelectDate from 'components/app/infobar/SelectDate'
import DashboardSettingsComponent from 'components/app/dashboard/DashboardSettings'
import DashboardSettings from 'components/app/dashboard/DashboardSettings'

type Item = [
  {
    content: string,
    onAction: any,
  },
  {
    content: string,
    onAction: any,
  },
]

/** Display all app options depending on which screen the user is on */
const MobileBarComponent = () => {
  const { lang, setLang, translate } = useLang()
  const screenMobile = useRecoilValue(atoms.screenInfo)
  const [screen, setScreen] = useRecoilState(atoms.screen)
  const [selectMobileDate, setSelectMobileDate] = useRecoilState(atoms.selectMobileDate)
  const [selectSettings, setSelectSettings] = useRecoilState(atoms.selectSettings)

  const [active, setActive] = useState(false)

  const toggleActive = useCallback(() => setActive((active) => !active), [])

  const handleSelectSettings = useCallback(() => {
    setSelectSettings((selectSettings) => !selectSettings)
    setActive((active) => !active)
  // only run once on load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectDate = useCallback(() => {
    setSelectMobileDate((selectMobileDate) => !selectMobileDate)
    setActive((active) => !active)
  // only run once on load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const languageReverse = translate('Common.languageReverse')

  const handleSetLang = () => {
    lang===Lang.En ? setLang(Lang.Ja) : setLang(Lang.En)}

  const activator = (
    <button onClick={toggleActive} type='button'>
      <Icon
        source={MobileHorizontalDotsMajor}
        color='base'
      />
    </button>
  )

  const items =
    screen===Screen.Welcome ? [
    {
      content: 'Dashboard',
      onAction: () => setScreen(Screen.Dashboard),
    },
    {
      content: 'Settings',
      onAction: () => setScreen(Screen.Settings),
    },
    {
      content: languageReverse,
      onAction: handleSetLang,
    },
  ]:
  screen===Screen.Dashboard ?
  [
    {
      content: 'Welcome',
      onAction: () => setScreen(Screen.Welcome),
      icon: PageMajor,
    },
    {
      content: 'Settings',
      onAction: () => setScreen(Screen.Settings),
      icon: PageMajor,
    },
    {
      content: 'Select date',
      onAction: handleSelectDate,
      icon: CalendarMinor,
    },
    {
      content: 'All Settings',
      onAction: handleSelectSettings,
      icon: SettingsMajor,
    },
  ]:
  screen===Screen.Settings ?
  [
    {
      content: 'Welcome',
      onAction: () => setScreen(Screen.Welcome),
    },
    {
      content: 'Dashboard',
      onAction: () => setScreen(Screen.Dashboard),
    },
  ]: [{
    content: '',
    onAction: () => {},
  },
  {
    content: '',
    onAction: () => {},
  },
]

  return (
    <div className='hidden justify-between items-center w-full h-full bg-gray-settings text-gray-900 border-b border-gray-200 py-6 px-6'>
      <div className='flex gap-3 items-center'>
        <StaticImage src='../../images/aidas-logo.png'
          alt='aidas logo'
          placeholder='blurred'
          width={30}
          height={30}
        />
        <p className='font-bold'>AIDAS</p>
      </div>
      <div>
        <Popover
          active={active}
          activator={activator}
          autofocusTarget='first-node'
          onClose={toggleActive}
        >
          <div className='whitespace-nowrap w-[14.4rem]'>
            <ActionList
            actionRole='menuitem'
            items={items}
          />
          </div>
        </Popover>
      </div>
      {selectMobileDate && <SelectDate />}
      {selectSettings && <DashboardSettings />}
    </div>
  )
}

export default MobileBarComponent

