import React from 'react'
import { useRecoilValue } from 'recoil'
import * as atoms from 'lib/atoms'
import TopCard from './TopCard'
import DataCard from '../DataCard'
import TreeMap from '../Charts/TreeMap'
import useLang from 'lib/hooks/useLang'
import useDashboardData from 'lib/hooks/useDashboardData'

import fbIcon from 'images/dashboard/grayFb.svg'
import googleIcon from 'images/dashboard/grayGoogle.svg'
import yahooIcon from 'images/dashboard/grayYahoo.svg'
import { fmtCurrency, formatPercent, numberWithCommas } from 'lib/method/formatNumber'
import { Tooltip } from '@shopify/polaris'
import { LoadingStatus } from 'lib/enum'
import { Div } from 'components/ui'


const TopInterestGroup = () => {
  const loadingData = useRecoilValue(atoms.loadingData)
  const { translate, setLang, lang } = useLang()
  const cpc = translate('Marketing.cpc')
  const spend = translate('Marketing.spend')
  const clicks = translate('Marketing.clicks')

  const {
    publisherLoadingProgress,
    interestData,
    interestFBData,
    interestGData,
    interestYJPData,
    calcClickBreakdown,
  } = useDashboardData()

  const getContent = (data:any) => {
    const def = `${cpc} = ${spend} / (${clicks}`
    const desc = `${numberWithCommas(data.rateNum)} / (${numberWithCommas(data.rateDen)}/1000) = ${fmtCurrency(data.rate)}`
    const chDesc = `${numberWithCommas(data.last.rateNum)} / (${numberWithCommas(data.last.rateDen)}/1000) = ${fmtCurrency(data.last.rate)}`
    return (<div>
      <p><b>{def}</b></p>
      <hr className='mt-2' />
      <p><b>This Period:</b> {desc}</p>
      <hr />
      <p><b>Last Period:</b> {chDesc}</p>
    </div>)
  }

  return (
    <>
      <div className='cardTop'>
        <div className='topCardStyle rounded-t-2xl pb-3'>
          <TopCard
            loadProgress={publisherLoadingProgress()}
            metric='Interest'
            value={interestData.value}
            changeValue={interestData.changePercent.value}
            dataPercentage={formatPercent(interestData.changePercent.value * 100)}
          />
        </div>
        <div className='lg:w-full 2xl:w-auto'>
          <DataCard
            dataTitle={cpc}
            changeValue={interestData.changePercent.rate}
            positivity={false}
            dataNumber={fmtCurrency(interestData.rate)}
            dataPercentage={formatPercent(interestData.changePercent.rate * 100)}
            toolTip={getContent(interestData)}
          />
        </div>
        <div className='topCardStyle pt-7 pb-4'>
          <TreeMap data={calcClickBreakdown()} metric='Interest' />
        </div>
        <Div iff={!!interestFBData?.value} className='lg:w-full 2xl:w-auto'>
          <DataCard
            loading={loadingData.facebook.total !== LoadingStatus.Completed}
            dataTitle='Facebook'
            changeValue={interestFBData.changePercent.rate}
            dataNumber={fmtCurrency(interestFBData.rate)}
            dataPercentage={formatPercent(interestFBData.changePercent.rate * 100)}
            publisherLogo={fbIcon}
            positivity={false}
            toolTip={getContent(interestFBData)}
          />
        </Div>
        <Div iff={!!interestGData?.value} className='lg:w-full 2xl:w-auto'>
          <DataCard
            loading={loadingData.google.total !== LoadingStatus.Completed}
            dataTitle='Google Ads'
            changeValue={interestGData.changePercent.rate}
            dataNumber={fmtCurrency(interestGData.rate)}
            dataPercentage={formatPercent(interestGData.changePercent.rate * 100)}
            publisherLogo={googleIcon}
            positivity={false}
            toolTip={getContent(interestGData)}
          />
        </Div>
        <Div iff={!!interestYJPData?.value} className='lg:w-full 2xl:w-auto'>
          <DataCard
            loading={loadingData.yjp.total !== LoadingStatus.Completed}
            dataTitle='Yahoo Japan'
            changeValue={interestYJPData.changePercent.rate}
            dataNumber={fmtCurrency(interestYJPData.rate)}
            dataPercentage={formatPercent(interestYJPData.changePercent.rate * 100)}
            publisherLogo={yahooIcon}
            positivity={false}
            toolTip={getContent(interestYJPData)}
          />
        </Div>
      </div>
    </>
  )
}

export default TopInterestGroup
