import React from 'react'

type TutorialVideosProps = {
}

const TutorialVideosComponent = () => {

  return (
    <>
      <div className='md:hidden flex justify-between flex-col py-6 md:flex-row items-center gap-10 pb-16 lg:gap-6'>
        <div>
          <iframe className='rounded-lg' width='260' height='165' src='https://www.youtube.com/embed/Iia8tjLdwqk' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
        <div>
          <iframe className='rounded-lg' width='260' height='165' src='https://www.youtube.com/embed/_ZnsQJKBw5M' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
        <div>
          <iframe className='rounded-lg' width='260' height='165' src='https://www.youtube.com/embed/_ZnsQJKBw5M' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
      </div>
      <div className='hidden md:flex flex-wrap justify-center py-6 md:flex-row items-center gap-10 pb-16 lg:gap-6 md:pb-24'>
        <div>
          <iframe className='rounded-lg' width='220' height='150' src='https://www.youtube.com/embed/Iia8tjLdwqk' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
        <div>
          <iframe className='rounded-lg' width='220' height='150' src='https://www.youtube.com/embed/_ZnsQJKBw5M' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
        <div>
          <iframe className='rounded-lg' width='220' height='150' src='https://www.youtube.com/embed/_ZnsQJKBw5M' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </div>
      </div>
    </>
  )
}

export default TutorialVideosComponent
