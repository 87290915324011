import React, { useEffect, useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Div } from 'components/ui'
import { ProgressBar } from '@shopify/polaris'
import { NivoData } from 'lib/types/nivoData'

type PieChartProps = {
  data: NivoData[]
  metric: 'Attention'|'Interest'|'Desire'|'Action'|'Satisfaction'
  loadProgress?: number
}

const METRIC_STYLE = 'aidasBorder3 flex flex-col items-center mx-6 lg:mx-1 lg:mt-0 mt-6 pt-8 pb-8 lg:h-full xl:px-6 lg:justify-center'

const PieChart = ({ data, metric, loadProgress=100 }:PieChartProps) => {
  const [colors, setColors] = useState([])

  useEffect(() => {
    const colorScheme = data
      .map(d => d.id==='facebook' ? 'rgba(4, 129, 248, 1)'
              : d.id==='google'   ? 'rgba(214, 48, 49, 1)'
              : d.id==='YJP'      ? '#ebeca0'
              : '',
      )
    setColors(colorScheme)
  }, [setColors, data])

    // const colors = metric==='Attention' ? ['rgba(4, 129, 248, 0.2)', 'rgba(4, 129, 248, 0.5)','rgba(4, 129, 248, 1)']
  //   : metric==='Interest' ? ['rgba(249, 135, 0, 0.2)','rgba(249, 135, 0, 0.5)','rgba(249, 135, 0, 1)']
  //   : metric==='Action' ? ['rgba(214, 48, 49, 0.2)','rgba(214, 48, 49, 0.5)','rgba(214, 48, 49, 1)']
  //   : metric==='Desire' ? ['rgba(8,181,55, 0.1)','rgba(8,181,55, 0.25)','rgba(8,181,55, 0.40)','rgba(8,181,55, 0.6)','rgba(8,181,55, 0.8)', 'rgba(8,181,55, 1)']
  //   : metric==='Satisfaction' ? ['rgba(127, 8, 247, 0.1)','rgba(127, 8, 247, 0.25)','rgba(127, 8, 247, 0.40)','rgba(127, 8, 247, 0.6)','rgba(8,181,55, 0.8)', 'rgba(8,181,55, 1)']
  //   : ''

  return (
    <>
      <div className={METRIC_STYLE}>
        <Div iff={typeof window!=='undefined' && !!ResponsivePie}>
          <div className='h-[270px] lg:w-22rem xl:w-[25rem] 2xl:w-[27rem]'>
            <ResponsivePie
              data={data}
              margin={{ top:40, right:0, bottom:40, left:0 }}
              sortByValue={true}
              innerRadius={0.2}
              padAngle={4}
              colors={{ scheme: 'pastel1' }}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              enableArcLinkLabels={true}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsDiagonalLength={6}
              arcLinkLabelsStraightLength={3}
              arcLinkLabelsTextOffset={2}
              arcLinkLabelsColor={{ from: 'color' }}
              enableArcLabels={true}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                  [ 'darker', 2 ],
                ],
              }}
              arcLinkLabelsTextColor='#777'
              legends={[]}
            />
          </div>
        </Div>
      </div>
      <Div iff={loadProgress<100}>
        <ProgressBar progress={Math.min(loadProgress,100)} size='small' color='success' />
      </Div>
    </>
  )
}

export default PieChart
