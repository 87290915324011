import React, { useEffect, useState } from 'react'
import { ResponsiveWaffle } from '@nivo/waffle'
import { NivoData } from 'lib/types/nivoData'
import { ProgressBar } from '@shopify/polaris'
import { Div } from 'components/ui'
import { sum } from '@sh/lib/calc'

type WaffleChartProps = {
  data: NivoData[]
  metric: 'Attention' | 'Interest' | 'Desire' | 'Action' | 'Satisfaction'
  loadProgress?: number
}

const WaffleChart = ({ data, metric, loadProgress = 100 }: WaffleChartProps) => {
  const [progressCount, setProgressCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressCount(c => c + 1)
    }, 150)
    if (progressCount === 90) {
      clearInterval(interval)
    }
    if (loadProgress === 100) {
      setProgressCount(100)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [progressCount, loadProgress])

  // const colors = metric==='Attention' ? ['rgba(4, 129, 248, 0.2)', 'rgba(4, 129, 248, 0.5)','rgba(4, 129, 248, 1)']
  //   : metric==='Interest' ? ['rgba(249, 135, 0, 0.2)','rgba(249, 135, 0, 0.5)','rgba(249, 135, 0, 1)']
  //   : metric==='Action' ? ['rgba(214, 48, 49, 0.2)','rgba(214, 48, 49, 0.5)','rgba(214, 48, 49, 1)']
  //   : metric==='Desire' ? ['rgba(8,181,55, 0.1)','rgba(8,181,55, 0.25)','rgba(8,181,55, 0.40)','rgba(8,181,55, 0.6)','rgba(8,181,55, 0.8)', 'rgba(8,181,55, 1)']
  //   : metric==='Satisfaction' ? ['rgba(127, 8, 247, 0.1)','rgba(127, 8, 247, 0.25)','rgba(127, 8, 247, 0.40)','rgba(127, 8, 247, 0.6)','rgba(8,181,55, 0.8)', 'rgba(8,181,55, 1)']
  //   : ''

  const sumData = () => {
    return sum(data.map(d => d.value))
  }

  return (
    <>
      <div className='aidasBorder3 flex flex-col items-center mx-6 lg:mx-1 lg:mt-0 mt-6 pt-4 lg:h-full xl:px-6 pb-8'>
        <Div iff={typeof window !== 'undefined' && !!ResponsiveWaffle}>
          <div className='h-[320px] px-12 lg:w-22rem xl:w-[25rem] 2xl:w-[27rem]'>
            <ResponsiveWaffle
              data={data}
              total={sumData()}
              rows={7}
              columns={7}
              margin={{ top: 10, right: 5, bottom: 100, left: 5 }}
              // colors={colors}
              colors={{ scheme: 'pastel1' }}
              borderColor={{
                from: 'color',
                modifiers: [
                  ['darker', 0.3],
                ],
              }}
              animate={true}
              motionStiffness={90}
              motionDamping={11}
              padding={5}
              emptyOpacity={0.3}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'column',
                  justify: false,
                  translateX: -40,
                  translateY: 100,
                  itemWidth: 100,
                  itemHeight: 15,
                  itemsSpacing: 1,
                  symbolSize: 15,
                  itemDirection: 'left-to-right',
                  itemTextColor: '#777',
                  effects: [{
                    on: 'hover',
                    style: { itemTextColor: '#000', itemBackground: '#f7fafb' },
                  }],
                },
              ]}
            />
          </div>
        </Div>
      </div>
      <Div iff={loadProgress < 100}>
        <ProgressBar size='small' progress={Math.min(100,progressCount)} />
      </Div>
    </>
  )
}

export default WaffleChart
