import React, { useEffect } from 'react'
import { Screen } from 'lib/enum'
import useLang from 'lib/hooks/useLang'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as atoms from 'lib/atoms'


type WelcomeTextProps = {
  demoMode: boolean
}

const WelcomeTextComponent = ({ demoMode=false }:WelcomeTextProps) => {
  const store = useRecoilValue(atoms.store)
  const [isFinishedSetup, setIsFinishedSetup] = useRecoilState(atoms.isFinishedSetup)
  const [screen, setScreen] = useRecoilState(atoms.screen)

  const { translate } = useLang()
  const getStarted = translate('Welcome.getStarted')

  useEffect(() => {
    setIsFinishedSetup(store.integrations.every(dataProvider => dataProvider.active === true))
  }, [store, setIsFinishedSetup])

  const switchPage = () => isFinishedSetup || demoMode
    ? setScreen(() => Screen.Dashboard)
    : setScreen(Screen.Settings)

  return (
    <>
      <h2 className='text-5xl font-semibold sm:text-6xl sm:whitespace-nowrap'>
        {translate('Welcome.title', {
          spanA: <span className='text-blue-attention'>A</span>,
          spanI: <span className='text-orange-interest'>I</span>,
          spanD: <span className='text-green-desire'>D</span>,
          spanAc: <span className='text-red-action'>A</span>,
          spanS: <span className='text-purple-satisfaction'>S</span>,
        })}
      </h2>
      <p className='pt-4 font-normal pr-20 md:pr-36 md:whitespace-nowrap sm:text-3xl 2xl:text-4xl xl:pr-[15rem] xl:leading-normal'>Know Your Customer</p>
      <p className='font-normal pr-20 pb-6 md:pr-36 md:pb-10 sm:text-3xl 2xl:text-4xl xl:pr-[15rem] xl:leading-normal'>Increase Sales</p>
      <button type='button' className='text-white bg-black rounded-xl px-10 py-3 z-20 relative text-lg md:text-xl lg:text-2xl font-semibold' onClick={switchPage}>{getStarted}</button>
    </>
  )
}

export default WelcomeTextComponent
