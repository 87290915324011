import React from 'react'
import { DisplayText, ProgressBar } from '@shopify/polaris'
import { useRecoilValue } from 'recoil'
import { AIDAS } from '@sh/constants/aidas'
import * as atoms from 'lib/atoms'
import { Div } from 'components/ui'
import { StaticImage } from 'gatsby-plugin-image'

import { FaBusinessTime, FaHandshake } from 'react-icons/fa'
import { GiClick, GiWorld } from 'react-icons/gi'
import { HiOutlineShoppingCart } from 'react-icons/hi'

type TopCardParams = {
  loadProgress: number
  metric: 'Attention'|'Interest'|'Desire'|'Action'|'Satisfaction'
  value: number
  percentage: number
}

const MetricTopCard = ({ loadProgress=0, metric, value, percentage }:TopCardParams) => {
  const lang = useRecoilValue(atoms.lang)

  const getImage = (metric) => {
    const result = metric==='Attention'? <GiWorld />
      :metric==='Interest'? <GiClick />
      :metric==='Desire'? <FaBusinessTime />
      :metric==='Action'? <HiOutlineShoppingCart />
      :metric==='Satisfaction'? <FaHandshake />
      :<></>
    return result
  }

  const getBgGradient = (metric) => {
    const result = metric==='Attention'? 'attentionBgGradient'
      :metric==='Interest'? 'interestBgGradient'
      :metric==='Desire'? 'desireBgGradient'
      :metric==='Action'? 'actionBgGradient'
      :metric==='Satisfaction'? 'satisfactionBgGradient'
      :<></>
    return result
  }

  const getMetric = () => AIDAS[metric][lang].metric

  return (
    <>
      <Div className={`flex flex-col justify-between items-center py-6 px-8 sm:py-16 text-white rounded-xl ${getBgGradient(metric)}`}
        lgScreen='px-5 flex-col py-10 h-full pt-0'
        xlScreen='px-9'
        xl2Screen='px-16'
      >
        <div style={{ fontSize:100}} className='lg:pt-16'>
          {getImage(metric)}
        </div>
        <div className='pb-2 text-center pt-4 lg:pb-[6.6rem] xl:pb-28'>
          <p className='text-base text-white pb-1 lg:text-xl font-semibold'>{getMetric()}</p>
          {/* lg text size  */}
          <div className='hidden lg:block xl:hidden'>
            <DisplayText size='medium'>
              {value}
            </DisplayText>
          </div>
          <div className='lg:hidden xl:block'>
            <DisplayText size='large'>
              {value}
            </DisplayText>
          </div>
        </div>
      </Div>
      <Div iff={loadProgress<100}>
        <ProgressBar progress={Math.min(loadProgress,100)} size='small' color='success' />
      </Div>
    </>
  )
}

export default MetricTopCard