import React from 'react'
import { AIDAS } from '@sh/constants/aidas'
import { Lang } from '@sh/enums/lang'
import textAttention from 'images/dashboard/textAttention.svg'
import textInterest from 'images/dashboard/textInterest.svg'
import textDesire from 'images/dashboard/textDesire.svg'
import textAction from 'images/dashboard/textAction.svg'
import textSatisfaction from 'images/dashboard/textSatisfaction.svg'

type AidasLetterProps = {
  metric: 'Attention'|'Interest'|'Desire'|'Action'|'Satisfaction'
  lang:   Lang
}

const AidasLetter = ({ metric, lang }:AidasLetterProps) => {

  const getTextSvg = () => {
    if (metric==='Attention') return textAttention
    if (metric==='Interest') return textInterest
    if (metric==='Desire') return textDesire
    if (metric==='Action') return textAction
    return textSatisfaction
  }

  const getAltText = () => {
    return metric + ' big ' + metric.substring(0,1)
  }

  const getTitle = () => AIDAS[metric][lang].name

  const getMetricSmallText = () => metric.substring(1, metric.length)

  const getFontClass = () => {
    const baseFont = 'text-2xl'
    const langFont = lang==='EN'
      ? 'font-bold'
      : ''
    return `${baseFont} ${langFont}`
  }

  const renderExtraDescription = () => {
    if (lang!=='JA') return <></>
    return (
      <span>
        ー {getTitle()}
      </span>
    )
  }

  return (
    <div className={'flex pt-6 pb-4 xl:pl-0 whitespace-nowrap items-end gap-1 justify-end ' + getFontClass()}>
      <img src={getTextSvg()} className='w-12 h-12' alt={getAltText()} />
      <p className={(metric==='Satisfaction' && 'lg:text-sm xl:text-2xl') || ''}>
        {getMetricSmallText()}
      </p>
      {renderExtraDescription()}
    </div>
    )

}

export default AidasLetter
