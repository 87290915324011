import React from 'react'
import { Screen } from 'lib/enum'
import { useRecoilState } from 'recoil'
import * as atoms from 'lib/atoms'
import { Select } from '@shopify/polaris'
import useLang from 'lib/hooks/useLang'
import pkg from '../../../../package.json'
import DropdownMenu from 'components/ui/DropdownMenu'

const WelcomeNavComponent = () => {

  const [ screen, setScreen ] = useRecoilState(atoms.screen)
  const { translate, setLang, lang } = useLang()

  const dashboard = translate('Common.dashboard')
  const settings = translate('Common.settings')

  const handleSetLang = (value) => setLang(value)

  return (
    <>
      <div className='flex justify-between px-6 py-6 sm:px-12 md:px-16 items-center 2xl:pt-8'>
        <div className='flex flex-col items-start'>
          <div className='sm:hidden'>
            <DropdownMenu menu='Welcome' />
          </div>
          <h2 className='text-2xl 2xl:text-3xl hidden sm:block'>Welcome!</h2>
          <div className='text-sm text-gray-300 ml-2'>V{pkg.version}</div>
        </div>
        <div className='flex items-baseline'>
          <button type='button' onClick={() => setScreen(Screen.Dashboard)}
            className='hidden sm:block hover:bg-black hover:rounded-lg px-8 py-2 hover:text-white active:bg-slate-400 xl:text-2xl 2xl:text-3xl mb-4'
          >
            {dashboard}
          </button>
          <button type='button' onClick={() => setScreen(Screen.Settings)}
            className='hidden sm:block hover:bg-black hover:rounded-lg px-8 py-2 hover:text-white active:bg-slate-400 xl:text-2xl 2xl:text-3xl mb-4'
          >
            {settings}
          </button>
          <div className='flex flex-col items-end'>
            <div className='text-gray-900 w-min md:pl-10'>
              <Select id=''label={translate('Common.language')} labelInline
              options={[
                {label: 'English', value: 'EN'},
                {label: '日本語',   value: 'JA'},
              ]}
              onChange={handleSetLang}
              value={lang} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeNavComponent
