import React, { useEffect, useState } from 'react'
import { Button } from '@shopify/polaris'
import { MobileCancelMajor } from '@shopify/polaris-icons'
import {useRecoilState, useSetRecoilState} from 'recoil'
import * as atoms from 'lib/atoms'
import useLang from 'lib/hooks/useLang'
import { serviceNames } from 'lib/const/humanReadableNames'
import SelectServiceOptions from './SelectServiceOptions'
import {Div} from 'components/ui'
import {AdAccount} from '@sh/models/interfaces'
import AccountsMgr from './AccountsMgr'
import {DataProvider, Store} from '@sh/models/classes'
import {Integration} from '@sh/enums'
import {gUnixSec} from '@1f8/commons/date'
import { getOneAdAccount } from 'lib/apis/mock'
import {sleep} from '@sh/lib/etc'
// import ModalCustom from './Modal'

type AddIntegrationProps = {
  connections: DataProvider[]
  onAddIntegration: (integration:Integration) => void
}

const AddIntegration = ({ onAddIntegration, connections }:AddIntegrationProps) => {
  const [optionSelected, setOptionSelected] = useState('')
  const [services, setServices] = useState<string[]>([])
  const { translate } = useLang()

  useEffect(() => {
    const _services = connections?.map(c => c.service) || []
    setServices(_services)
    if (!services.includes(optionSelected)) setOptionSelected(services[0])
  }, [connections])

  // const [open, setOpen] = useState(false)
  // const [selectedAccount, setSelectedAccount] = useState<AdAccount>()

  // const [adAccountsRecord, setAdAccountsRecord] = useRecoilState(atoms.adAccountsRecord)
  // const [settingsHistory, setSettingsHistory] = useRecoilState(atoms.settingsHistory)
  // const [accountsMapToService, setAccountsMapToService] = useRecoilState<Record<string, AdAccount[]>>(atoms.accountsMapToService)
  // const [store, setStore] = useRecoilState(atoms.store)
  // const setToast = useSetRecoilState(atoms.toastActive)
  // const [modalActive, setModalActive] = useRecoilState(atoms.modalActive)
  // const [confirmed, setConfirmed] = useRecoilState(atoms.confirmed)
  // const [isAccountClicked, setIsAccountClicked] = useRecoilState<Record<string, boolean>>(atoms.accountClicked)

  // key = '{service}|{account}'
  // const handleToggle = () => {
  //   setOpen(!open)
  // }

  // const onSelectAdAccount = async (account:AdAccount, index?:number) => {
  //   setIsAccountClicked(prev => ({
  //     ...prev,
  //     [account?.id]: true,
  //   }))
  //   // confirmtaion modal
  //   if (!confirmed) {
  //     setSelectedAccount(account)
  //     setModalActive({
  //       active: true,
  //       message: `Are you sure you want to add an additional account ${account?.name}?`,
  //     })
  //     return
  //   }
  //   // TODO: update getOneAdAccount with actual api call
  //   const data = await getOneAdAccount(account, optionSelected)
  //   const provider = new DataProvider(data)
  //   // create a new store instance
  //   const _store = new Store(store.getItem())
  //   // assign new empty integration
  //   _store.integrations.push(provider)
  //   setSettingsHistory(prev => [
  //     ...prev,
  //     {
  //       desc: `Adding integration: ${optionSelected} `,
  //       store: _store,
  //       ts: gUnixSec(),
  //     },
  //   ])
  //   setAccountsMapToService(prev => {
  //     return {
  //       ...prev,
  //       [optionSelected]: prev[optionSelected]
  //         .filter((item) => item.id !== account?.id),
  //     }
  //   })
  //   setOpen(false)
  //   sleep(2000).then(() => {
  //     setToast({
  //       active: true,
  //       message: `${account?.name} has been added at the bottom.`,
  //     })
  //   })
  // }

  // // confirmation modal checks
  // useEffect(() => {
  //   // If modal confirm is clicked, add account
  //   if (confirmed) {
  //     onSelectAdAccount(selectedAccount)
  //   }
  //   // reset setConfirmed to false
  //   setConfirmed(false)
  //    // Only run this when confirmed updates
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [confirmed])

  // const handleConfirm = () => {
  //   setConfirmed(true)
  //   setModalActive({ message: '', active: false })
  // }

  // useEffect(() => {
  //   if (!settingsHistory?.length) return
  //   const id = setTimeout(() => {
  //     setIsAccountClicked({})
  //   }, 2000)
  //   return () => clearTimeout(id)
  // }, [settingsHistory, setIsAccountClicked])

  return (
    <div className='flex flex-row justify-start items-end'>
      {/* <ModalCustom handleConfirm={handleConfirm} /> */}
      <div className='w-[180px]'>
        <SelectServiceOptions
          label='Add New Integration'
          onSelectChange={event => setOptionSelected(event)}
          selected={optionSelected}
          services={connections?.map(c => c.service) || []}
        />
      </div>
      <div className='ml-4'>
        <Button primary
          onClick={() => onAddIntegration(optionSelected as Integration)}
        >
          {translate('Settings.add')}
        </Button>
      </div>
    </div>
  )
}

export default AddIntegration
