import React from 'react'
import { DisplayText, Spinner } from '@shopify/polaris'
import { Div } from 'components/ui'
import TrendIndicator from 'components/ui/TrendIndicator'
import gaIcon from 'images/dashboard/grayGA.svg'
import yahooIcon from 'images/dashboard/grayYahoo.svg'
import shoifyIcon from 'images/dashboard/grayShopify.svg'


type DataCardProps = {
  // TODO remove optional ? on loading
  loading?: boolean
  dataTitle: string
  changeValue: number
  dataNumber: string
  dataPercentage: string
  positivity?: boolean
  publisherLogo?: any
  toolTip?: React.ReactNode
}

const DataCard:React.FC<DataCardProps> = ({
  loading=false,
  dataTitle,
  changeValue,
  dataNumber,
  dataPercentage,
  publisherLogo,
  positivity=true,
  toolTip,
}) => {
  return (
    <>
      <Div iff={!loading} className='bg-white w-80 flex flex-col items-start text-left px-10 py-8 relative mb-1'
        smScreen='py-4 w-[42rem] justify-center text-center items-center'
        mdScreen='w-[51rem]'
        lgScreen='w-full items-start text-left pl-2 mb-1 py-8'
        xlScreen='w-[19rem] py-6 pl-8'
        xl2Screen='w-[20.5rem]'
        toolTip={toolTip}>
        <p className='text-sm xl:text-xl lg:text-left w-full font-semibold pb-4 lg:pb-8'>
          {dataTitle}
        </p>
        {/* lg text size  */}
        <div className='hidden lg:block xl:hidden'>
          <DisplayText size='medium'>
            {dataNumber}
          </DisplayText>
        </div>
        <div className='lg:hidden xl:block'>
          <DisplayText size='large'>
            {dataNumber}
          </DisplayText>
        </div>
        <TrendIndicator
          changeValue={changeValue}
          dataPercentage={dataPercentage}
          positivity={positivity}
          vsNotation='last week'
        />
        {publisherLogo && (
          <div className={publisherLogo === gaIcon
            ? 'absolute right-9 top-8 sm:top-5 sm:right-44 md:right-56 w-8 lg:w-9 lg:right-4 lg:top-7 xl:right-6 xl:top-6'
            : publisherLogo === yahooIcon
            ? 'absolute right-9 top-5 sm:top-1 sm:right-44 md:right-56 w-12 lg:right-3 lg:top-7 xl:right-6 xl:top-6'
            : publisherLogo === shoifyIcon
            ? 'absolute right-9 top-5 sm:top-2 sm:right-44 md:right-56 w-12 lg:w-14 lg:right-1 lg:top-5 xl:right-4 xl:top-4'
            : 'absolute right-9 top-7 sm:top-4 sm:right-44 md:right-56 w-12 lg:right-3 lg:top-7 xl:right-6 xl:top-5'}
          >
            {publisherLogo ? <img src={publisherLogo} alt='publisher logo' width={45} height={45} /> : <></>}
          </div>
        )}
      </Div>
      <Div iff={loading} className='flex justify-center items-center py-24'>
        <Spinner accessibilityLabel={`${dataTitle} data is loading...`} size='small' />
      </Div>
    </>
  )
}

export default DataCard
