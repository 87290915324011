import React from 'react'
import { Integration } from '@sh/enums'
import { AdData } from '@sh/models/classes'
import { round } from '@sh/lib/calc'
import PublisherCardComponent from './PublisherCard'
import useDashboardData from 'lib/hooks/useDashboardData'
import { fmtCurrency, sanitizeNan } from 'lib/method/formatNumber'
import { Div } from 'components/ui'
import { Spinner } from '@shopify/polaris'


type PublisherCampaignSummaryProps = {
  metric: 'Attention'|'Interest'|'Desire'|'Action'|'Satisfaction'
}

type CardData = {
  integration: Integration
  name: string
  sorter: number
  value: number
  formattedValue: string
  lastValue: number
  percentChange: number
}

const PublisherCampaignSummaryComponent = ({ metric }:PublisherCampaignSummaryProps) => {
  const {
    shopifyChannels,
    shopifyChannelsLast,
    fbCampaigns,
    fbCampaignsLast,
    googleCampaigns,
    googleCampaignsLast,
    yjpCampaigns,
    yjpCampaignsLast,
    gaDataChannels,
    gaDataChannelsLast,
  } = useDashboardData()

  const calculate = ():CardData[] => {
    const allCampaigns:CardData[] = []

    if (metric==='Attention' || metric==='Interest') {
      const _addCardData = (integration:Integration, curs:AdData[], lasts:AdData[]) => {
        for (const cur of curs) {
          const last = lasts?.find(d => d.description===cur.description)
          const value = metric==='Attention' ? cur.cpm(2) : cur.cpc(2)
          const lastValue = last
            ? (metric==='Attention' ? last.cpm() : last.cpc())
            : 0
          allCampaigns.push({
            integration,
            name: cur.description,
            sorter: cur.spend,
            value,
            formattedValue: fmtCurrency(value),
            lastValue,
            percentChange: round(value/lastValue - 1, 2)*100,
          })
        }
      }
      if (fbCampaigns) {
        _addCardData(Integration.FACEBOOK, fbCampaigns, fbCampaignsLast)
      }
      if (googleCampaigns) {
        _addCardData(Integration.GOOGLE, googleCampaigns, googleCampaignsLast)
      }
      if (yjpCampaigns) {
        _addCardData(Integration.YJP, yjpCampaigns, yjpCampaignsLast)
      }
    }

    if (metric==='Desire' || metric==='Action') {
      if (gaDataChannels) {
        for (const cur of gaDataChannels) {
          const last = gaDataChannelsLast?.find(d => d.description===cur.description)
          const value = metric==='Desire' ? cur.engagementRate(4) : cur.conversionRate(4)
          const lastValue = last
            ? (metric==='Desire' ? last.engagementRate() : last.conversionRate())
            : 0
          allCampaigns.push({
            integration: Integration.GA,
            name: cur.description,
            sorter: cur.sessions,
            value,
            formattedValue: sanitizeNan(round(value*100, 2)) + '%',
            lastValue,
            percentChange: round(value/lastValue - 1, 2)*100,
          })
        }
      }
    }

    if (metric==='Satisfaction') {
      if (shopifyChannels) {
        for (const cur of shopifyChannels) {
          const last = shopifyChannelsLast?.find(d => d.description===cur.description)
          const value = round(cur.customerReturningRate(), 2)
          const lastValue = last
            ? last.customerReturningRate()
            : 0
          allCampaigns.push({
            integration: Integration.SHOPIFY,
            name: cur.description,
            sorter: cur.allRevenue(),
            value,
            formattedValue: sanitizeNan(round(value*100, 2)) + '%',
            lastValue,
            percentChange: round(value/lastValue - 1, 2)*100,
          })
        }
      }
    }

    return allCampaigns
      .sort((a,b) => b.sorter - a.sorter)
  }

  return (
    <div className='flex flex-wrap gap-2 px-6 lg:px-3 xl:px-6 pt-6 xl:pt-7'>
      {calculate().map((c, i) => (
        <PublisherCardComponent
          key={`${metric}${c.integration}${c.name}`}
          integration={c.integration}
          name={c.name}
          value={c.formattedValue}
          percentChange={c.percentChange} />
      ))}
      {calculate().length===0 &&
        <Div className='flex justify-center items-center py-24 w-full'>
          <Spinner accessibilityLabel='publisher card data is loading...' size='small' />
        </Div>}
    </div>
  )
}

export default PublisherCampaignSummaryComponent
