import React from 'react'
import { DisplayText, ProgressBar } from '@shopify/polaris'
import { useRecoilValue } from 'recoil'
import { AIDAS } from '@sh/constants/aidas'
import * as atoms from 'lib/atoms'
import AidasLetter from 'components/app/dashboard/AidasLetter'
import { Div } from 'components/ui'
import { Lang } from '@sh/enums'
import { numberWithCommas } from 'lib/method/formatNumber'
import TrendIndicator from 'components/ui/TrendIndicator'

type TopCardParams = {
  loadProgress: number
  metric: 'Attention' | 'Interest' | 'Desire' | 'Action' | 'Satisfaction'
  value: number
  changeValue?: number
  dataPercentage?: string
}

const TopCard = ({ loadProgress = 0, metric, value, changeValue, dataPercentage }: TopCardParams) => {
  const lang = useRecoilValue(atoms.lang)

  const getMetric = () => AIDAS[metric][lang].metric

  return (
    <>
      <Div className='flex flex-col border-gray-divider justify-between py-4 px-8 items-start'
        smScreen='items-center px-0'
        lgScreen='px-2 flex-col pt-0 items-start'
        xlScreen='pl-7'
      >
        <div>
          <AidasLetter lang={lang} metric={metric} />
        </div>
        <div className='pb-2'>
          <span className='text-base text-gray-need-info 2xl:text-lg'>
            {getMetric()}
          </span>
        </div>
        {/* lg text size  */}
        <div className='hidden lg:block xl:hidden'>
          <DisplayText size='medium'>
            {numberWithCommas(value)}
          </DisplayText>
        </div>
        <div className='lg:hidden xl:block'>
          <DisplayText size='large'>
            {numberWithCommas(value)}
          </DisplayText>
        </div>
        <TrendIndicator
          changeValue={changeValue}
          dataPercentage={dataPercentage}
          positivity={true}
          vsNotation='last week'
        />
      </Div>
      <Div iff={loadProgress < 100}>
        <ProgressBar progress={Math.min(loadProgress,100)} size='small' color='success' />
      </Div>
    </>
  )
}

export default TopCard