import React, { useEffect, useMemo, useState } from 'react'
import { Collapsible, ActionList, Spinner, Card, TextContainer, ButtonGroup, Button, Select } from '@shopify/polaris'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as atoms from 'lib/atoms'
import { DataProvider, Store } from '@sh/models/classes'
import { Integration, IntegrationStatus } from '@sh/enums'
import { AdAccount } from '@sh/models/interfaces'
import { gUnixSec } from '@1f8/commons/date'
import { StaticImage } from 'gatsby-plugin-image'
import useLang from 'lib/hooks/useLang'
import {PopupBase} from 'lib/atoms'
import { Div } from 'components/ui'
import googleAdsLogo from '../../../images/settings/googleAdsLogo.png'
import gaLogo from '../../../images/settings/exGaLogo.svg'
import fbLogo from '../../../images/settings/fbLogo.svg'
import yjLogo from '../../../images/settings/yjLogo.svg'
import ConnectCardLine from './ConnectCardLine'

type ConnectIntegrationProps = {
  children: JSX.Element
  connection: DataProvider
  onConfirmConnection: (connection:DataProvider) => void
  onRemoveConnection: (connection:DataProvider) => void
  onSelectAdAccount: (connection:DataProvider, accountID:string) => void
}

const LOGOS = {
  [Integration.FACEBOOK]: fbLogo,
  [Integration.GOOGLE]: googleAdsLogo,
  [Integration.GA]: gaLogo,
  [Integration.YJP]: yjLogo,
}

const ConnectIntegrationComponent = ({
  connection,
  // onUpdateAccountList,
  onConfirmConnection,
  onRemoveConnection,
  onSelectAdAccount,
  children,
}:ConnectIntegrationProps) => {
  const [newAccount, setNewAccount] = useState<string>('')
  const [loading, setLoading] = useState(false)

  // collapsable open/close
  const [showDetail, setShowDetail] = useState(false)
  const [showSelectAccount, setShowSelectAccount] = useState(false)
  const [store] = useRecoilState(atoms.store)
  const [settingsHistory, setSettingsHistory] = useRecoilState(atoms.settingsHistory)
  const setToast = useSetRecoilState<PopupBase>(atoms.toastActive)
  // Connection status
  const integrationStatus = useMemo(() => connection.getStatus(), [connection])
  const { translate } = useLang()
  const [activeModal, setActiveModal] = useState(false)

  const tr = {
    account: translate('Settings.account'),
    username: translate('Settings.username'),
    accountID: translate('Settings.accountID'),
    active: translate('Settings.active'),
    secondaryID: translate('Settings.secondaryID'),
    refreshAccount: translate('Settings.refreshAccount'),
    selectAccount: translate('Settings.selectAccount'),
    differentAccount: translate('Settings.differentAccount'),
    confirmAccount: translate('Settings.confirmAccount'),
    deleteConnection: translate('Settings.deleteConnection'),
    connected: translate('Settings.connected'),
    notConnected: translate('Settings.notConnected'),
    incompleted: translate('Settings.incomplete'),
    removeAccountDesc: translate('Settings.removeAccountDesc'),
    cancel: translate('Settings.cancel'),
    deleteAccount: translate('Settings.deleteAccount'),
  }

  const clickRemoveConnection = (connection:DataProvider) => {
    setLoading(true)
    onRemoveConnection(connection)
    setToast({
      active: true,
      message: `${connection.service} has been Removed`,
    })
    setActiveModal(!activeModal)

    // // current integration context
    // const _integration = new DataProvider(integration.getItem())
    //     // current store context
    //     const _store = new Store(store.getItem())
    //     // assign accountID to integration
    //     _integration.account = ''
    //     _integration.accountID = ''
    //     _integration.username = ''
    //     _integration.active = false

    // // if store integration key found, set the integration to that key
    // const i = _store.integrations
    //   .findIndex(si => si.getKey()===integration.getKey())
    // if (_store.integrations[i]) _store.integrations[i] = _integration

    // // set updated store to settingHistory
    // setSettingsHistory(prev => [
    //   ...prev,
    //   {
    //     desc: `Removed ${integration.service} Account`,
    //     store: _store,
    //     ts: gUnixSec(),
    //   },
    // ])
  }

  useEffect(() => {
    if (!connection?.accountID) setShowDetail(true)
    if (connection?.accountID) setNewAccount(connection.accountID)
  }, [])

  // stopping the loading circle animation here
  // useEffect(() => {
  //   if (!settingsHistory.length) return
  //   const id = setTimeout(() => {
  //     setIsAccountClicked({})
  //     setShowSelectAccount(false)
  //   }, 2000)
  //   return () => clearTimeout(id)
  // }, [settingsHistory, onSelectAdAccount])

  const accountConnection = () => {
    switch(connection.getStatus()){
      case IntegrationStatus.CONNECTED:
        return (
          <>
            <p className='pr-1 lg:pr-0 text-green-connected font-semibold 2xl:text-2xl'>{tr.connected}</p>
            <div className='pr-2'>
              <StaticImage src='../../../images/settings/connected.svg'
              alt='account connected'
              placeholder='blurred'
              width={22}
              height={22}
            />
            </div>
          </>
        )
      case IntegrationStatus.NOT_CONNECTED:
        return (
          <>
            <p className='pr-1 lg:pr-0 text-red-notConnected font-semibold 2xl:text-2xl'>{tr.notConnected}</p>
            <div className='pr-2'>
              <StaticImage src='../../../images/settings/notConnected.svg'
              alt='account not connected'
              placeholder='blurred'
              width={21}
              height={21}
            />
            </div>
          </>
        )
      case IntegrationStatus.INCOMPLETE:
        return (
          <>
            <p className='pr-1 lg:pr-0 text-gray-need-info font-semibold text-base lg:text-xl text-right 2xl:text-2xl'>
              {tr.incompleted}
            </p>
            <div className='pr-2'>
              <StaticImage src='../../../images/settings/needInfo.svg'
              alt='account incompleted'
              placeholder='blurred'
              width={21}
              height={21}
            />
            </div>
          </>
        )
    }
  }

  const onConfirmAdAccount = () => {
    setLoading(true)
    onSelectAdAccount(connection, newAccount)
    // TODO: sync this loading icon with actually API call?
    setTimeout(() => {
      setLoading(false)
      setShowSelectAccount(false)
    }, 500)
  }

  // View Renders

  const displayIntegrationTitle = (integration:DataProvider) => {
    return integration.accountID
      ? integration.account
      : integration.service
  }

  const renderArrow = () => {
    return showDetail
      ? ( <StaticImage src='../../../images/settings/closeArrow.svg'
            alt='account' placeholder='blurred'
            width={12} height={7}
          />
      ):( <StaticImage src='../../../images/settings/arrow.svg'
            alt='account' placeholder='blurred'
            width={12} height={7}
          />
      )
  }

  return (
    <>
      <div className='relative'>
        {/* Header */}
        <div className='w-full flex flex-row justify-between items-center p-4 cursor-pointer bg-gray-settings mt-10 rounded-2xl lg:px-6 lg:py-5'
          onClick={() => setShowDetail(!showDetail)}
        >
          <div className='flex gap-4 items-center'>
            {connection.service==='Google Analytics'
              ? <img src={LOGOS[connection.service]} alt={'publisher\'s logo'} width={26} height={26} />
              : <img src={LOGOS[connection.service]} alt={'publisher\'s logo'} width={30} height={30} />}
            <p className='font-bold'>
              {displayIntegrationTitle(connection)}
            </p>
          </div>
          <div className='flex items-center gap-2 md:gap-6 lg:gap-4'>
            {accountConnection()}
            {renderArrow()}
          </div>
        </div>
        {/* Collapsible Body */}
        <Collapsible
          id={`${connection.service}${connection.account||'NEW'}`}
          open={showDetail}
          transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
        >
          <div className='flex flex-col px-2 pb-4 md:px-6 2xl:text-2xl border-b-2 border-r-2 border-l-2 rounded-b-2xl -mt-2 border-gray-settings'>
            {/* Text Fields */}
            <div className='pb-4 px-2 sm:px-4 lg:pt-2'>
              <ConnectCardLine
                title='username'
                label={tr.username}
                text={connection.username}
              />
              <ConnectCardLine
                title='account'
                label={tr.account}
                text={connection.accountID + ' - ' + connection.account}
              />
              {/* <ConnectCardLine
                title='active'
                label={tr.active}
                text={integration.active ? ''}
              /> */}
              {!!connection.secondaryID && (
                <ConnectCardLine
                  title='secondaryID'
                  label={tr.secondaryID}
                  text={connection.secondaryID}
                />
              )}
            </div>
            {/* Display Login if not Connected */}
            <Div iff={integrationStatus===IntegrationStatus.NOT_CONNECTED} className='pb-8 lg:pb-10 pt-8 mx-auto'>
              {children}
            </Div>
            {/* Display Options for Completed */}
            <Div iff={integrationStatus!==IntegrationStatus.NOT_CONNECTED}>
              <div className='flex flex-row justify-center items-start pt-6 sm:pt-10 pb-5'>
                <div className='w-96 mr-5 flex flex-col justify-start'>
                  <button type='button' className='px-10 py-4 border-2 rounded-2xl border-gray-need-info'
                    onClick={() => setShowSelectAccount(b => !b)}
                  >
                    {showSelectAccount?'▼':'▶︎'} {tr.differentAccount}
                  </button>
                  <Collapsible
                    id={`${connection.service}${connection.account}${connection.accountID||'NEW'}`}
                    open={showSelectAccount}
                    transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                  >
                    <div className='w-full mt-8'>
                      <Select
                        label=''
                        options={connection.auth?.accounts?.map((a) => ({
                          label: a.id+' - '+a.name,
                          value: a.id,
                        }))}
                        onChange={setNewAccount}
                        value={newAccount}
                      />
                    </div>
                    <button type='button'
                      className={!newAccount || connection.accountID===newAccount
                        ? 'px-10 py-4 border-2 rounded-2xl mt-8 w-96 border-gray-need-info cursor-not-allowed'
                        : 'px-10 py-4 border-2 rounded-2xl mt-8 w-96 border-green-connected text-green-connected'}
                      disabled={!newAccount || connection.accountID===newAccount}
                      onClick={onConfirmAdAccount}
                    >
                      {tr.confirmAccount}
                    </button>
                  </Collapsible>
                </div>
                <button type='button' className='px-10 py-4 border-2 rounded-2xl w-96 border-red-notConnected text-red-notConnected' onClick={() => setActiveModal(!activeModal)}>
                  {tr.deleteConnection}
                </button>
              </div>
              <Div iff={loading} className='flex justify-center items-center py-24'>
                <Spinner accessibilityLabel='data is loading...' size='small' />
              </Div>
            </Div>
          </div>
        </Collapsible>
        {/* Confirmation Modal */}
        { activeModal && (
          <Div className='w-96 py-8 sm:w-[33rem] bg-white border border-gray-settings rounded-xl shadow-lg px-8 mx-auto mt-4 z-20 modalCenter'>
            <TextContainer>
              <p>
                {tr.removeAccountDesc}
              </p>
            </TextContainer>
            <div className='pt-7'>
              <ButtonGroup>
                <Button onClick={() => setActiveModal(false)}>
                  {tr.cancel}
                </Button>
                <Button destructive onClick={() => clickRemoveConnection(connection)}>
                  {tr.deleteAccount}
                </Button>
              </ButtonGroup>
            </div>
          </Div>
        )}
      </div>
    </>
  )
}

export default ConnectIntegrationComponent
