import React from 'react'
import { useRecoilValue } from 'recoil'
import { round } from '@sh/lib/calc'
import { Metric } from '@sh/enums/metric'

import * as atoms from 'lib/atoms'
import MetricTopCard from './MetricTopCard'
import MetricDataCard from './MetricDataCard'
import PieChart from '../Charts/PieChart'
import useDashboardData from 'lib/hooks/useDashboardData'
import { Div } from 'components/ui'
import { NivoData } from 'lib/types/nivoData'
import { fmtCurrency, formatPercent } from 'lib/method/formatNumber'
import WaffleChart from '../Charts/WaffleChart'
import { Integration } from '@sh/enums'
import { AdData } from '@sh/models/classes'
// import BarChart from '../Charts/BarChart'


type MetricSummaryProps = {
  metric: Metric
}

const MetricSummaryComponent = ({ metric }:MetricSummaryProps) => {
  const lang = useRecoilValue(atoms.lang)

  const {
    attentionData,
    interestData,
    desireData,
    actionData,
    satisfactionData,

    shopifyChannels,
    fbCampaigns,
    googleCampaigns,
    yjpCampaigns,
    gaDataChannels,

    publisherLoadingProgress,
    gaLoadingProgress,
    shopifyLoadingProgress,
    shopifyChannelsLoadingProgress,
    calcImpressionBreakdown,
    calcClickBreakdown,
    calcEngagementsBreakdown,
    calcConversionBreakdown,
    calcCustomerBreakdown,
  } = useDashboardData()

  // TODO REFACTOR to constant
  const getMetric = () => metric==='Attention' ? 'Impressions'
                        : metric==='Interest' ? 'Clicks'
                        : metric==='Desire' ? 'Engagements'
                        : metric==='Action' ? 'Orders'
                        : 'Re-Orders'
  const getMetric2 = () => metric==='Attention' ? 'CPM'
                         : metric==='Interest' ? 'CPC'
                         : metric==='Desire' ? 'Engagement Rate'
                         : metric==='Action' ? 'Conversion Rate'
                         : 'Repeat-Rate'

  const getLoadingPercent = () => metric==='Attention' ? publisherLoadingProgress()
                                : metric==='Interest' ? publisherLoadingProgress()
                                : metric==='Desire' ? gaLoadingProgress()
                                : metric==='Action' ? shopifyLoadingProgress()
                                : shopifyLoadingProgress()

  const getPieLoadingPercent = () => metric==='Attention' ? publisherLoadingProgress()
                                   : metric==='Interest' ? publisherLoadingProgress()
                                   : metric==='Desire' ? gaLoadingProgress()
                                   : metric==='Action' ? publisherLoadingProgress()
                                   : shopifyChannelsLoadingProgress()

  const calculateValue = () => {
    if (metric==='Attention') return attentionData.value
    if (metric==='Interest') return interestData.value
    if (metric==='Desire') return desireData.value
    if (metric==='Action') return actionData.value
    if (metric==='Satisfaction') return satisfactionData.value
    return 0
  }

  const calculateRate = ():number => {
    if (metric==='Attention') return attentionData.rate
    if (metric==='Interest') return interestData.rate
    if (metric==='Desire') return desireData.rate
    if (metric==='Action') return actionData.rate
    if (metric==='Satisfaction') return satisfactionData.rate
    return 0
  }

  const formatRate = ():string => {
    const r = calculateRate()
    if (metric==='Attention') return fmtCurrency(r)
    if (metric==='Interest')  return fmtCurrency(r)
    if (metric==='Desire')       return (formatPercent(round(r, 0)))
    if (metric==='Action')       return (formatPercent(round(r, 2)))
    if (metric==='Satisfaction') return (formatPercent(round(r, 0)))
    return r.toString()
  }

  const calculateValueChange = ():number => {
    if (metric==='Attention') return attentionData.changePercent.value
    if (metric==='Interest') return interestData.changePercent.value
    if (metric==='Desire') return desireData.changePercent.value
    if (metric==='Action') return actionData.changePercent.value
    if (metric==='Satisfaction') return satisfactionData.changePercent.value
    return 0
  }

  const getBreakdown = ():NivoData[] => {
    if (metric==='Attention') return calcImpressionBreakdown()
    if (metric==='Interest') return calcClickBreakdown()
    if (metric==='Desire') return calcEngagementsBreakdown()
    if (metric==='Action') return calcConversionBreakdown()
    if (metric==='Satisfaction') return calcCustomerBreakdown()
    return []
  }

  const getChannelBreakdown = ():NivoData[] => {
    const allCampaigns:NivoData[] = []

    if (metric==='Attention' || metric==='Interest') {
      const _addCardData = (curs:AdData[]) => {
        for (const cur of curs) {
          const value = metric==='Attention' ? cur.cpm(2) : cur.cpc(2)
          allCampaigns.push({ id:cur.description, label:cur.description, value })
        }
      }
      if (fbCampaigns) _addCardData(fbCampaigns)
      if (googleCampaigns) _addCardData(googleCampaigns)
      if (yjpCampaigns) _addCardData(yjpCampaigns)
    }

    if (metric==='Desire' || metric==='Action') {
      if (gaDataChannels) {
        for (const cur of gaDataChannels) {
          const value = metric==='Desire' ? cur.engagementRate(4) : cur.conversionRate(4)
          allCampaigns.push({ id:cur.description, label:cur.description, value })
        }
      }
    }

    if (metric==='Satisfaction') {
      if (shopifyChannels) {
        for (const cur of shopifyChannels) {
          const value = round(cur.customerReturningRate(), 2)
          allCampaigns.push({ id:cur.description, label:cur.description, value })
        }
      }
    }

    const top5 = allCampaigns
      .filter(c => c.value > 0)
      .slice(0,5)
    if (allCampaigns.length>5 && !top5.find(c => c.id==='Other')) {
      top5.push({ id:'Other', label:'Other', value:0 })
    }
    if (allCampaigns.length>5) {
      allCampaigns
        .slice(5)
        .forEach(c => {
          top5.find(tc => tc.id==='Other').value += c.value
        })
    }

    return top5
      .sort((a,b) => b.value - a.value)
  }

  const getPositivity = () => metric==='Attention' ? false
                            : metric==='Interest' ? false
                            : true

  return (
    <>
      <div className='lg:flex justify-between xl:pr-4 px-2'>
        <div className='flex px-6 lg:pl-3 lg:pr-1 xl:px-3 xl:pl-6 gap-4 lg:gap-2 xl:gap-3'>
          <div className='w-1/2 lg:pr-2 lg:w-60 xl:w-[17rem] 2xl:w-72'>
            <MetricTopCard
              loadProgress={getLoadingPercent()}
              metric={metric}
              value={calculateValue()}
              percentage={calculateValueChange()}
            />
          </div>
          <div className='w-1/2 lg:w-60 xl:w-[17rem] 2xl:w-72'>
            <MetricDataCard
              loading={getLoadingPercent()!==100}
              dataTitle={getMetric2()}
              dataNumber={formatRate()}
              changeValue={calculateValueChange()}
              dataPercentage={formatPercent(calculateValueChange())}
              positivity={getPositivity()}
            />
          </div>
        </div>
        <Div iff={!!getBreakdown()?.length}
          className='lg:mx-2 lg:w-22rem xl:w-[25rem] 2xl:w-[27rem] grow'
        >
          <PieChart metric={metric} data={getBreakdown()} loadProgress={getPieLoadingPercent()}
          />
        </Div>
        <Div iff={!!getBreakdown()?.length}
          className='lg:mr-2 lg:w-[21rem] xl:w-[25rem] 2xl:w-[27rem]'
        >
          <WaffleChart data={getChannelBreakdown()} metric={metric} loadProgress={getPieLoadingPercent()}
          />
        </Div>
        <Div iff={!getBreakdown()?.length}
          className='lg:mx-2 lg:w-22rem xl:w-[25rem] 2xl:w-[27rem]'
        >
          <Div className='bg-gray-200 w-full h-full flex items-center justify-center'>
            No Data
          </Div>
        </Div>
        <div>
          {/* <div className='lg:h-52 lg:w-22rem xl:w-130 2xl:w-[35rem] mx-6 lg:mx-0 lg:pt-0 mt-6 lg:mt-4'>
          <Div className='bg-gray-200 w-full h-full flex items-center justify-center'>
            近日公開
          </Div>
        </div> */}
        </div>
      </div>
    </>
  )
}

export default MetricSummaryComponent