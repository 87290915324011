import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Div } from 'components/ui'
import * as atoms from 'lib/atoms'
import { Screen } from 'lib/enum'
import useLang from 'lib/hooks/useLang'
import DashboardScreen from './DashboardScreen'
import SettingsScreen from './SettingsScreen'
import WelcomeScreen from './WelcomeScreen'
import MobileBar from 'components/ui/MobileBar'

const AppComponent = ({ demoMode=false }) => {
  const { lang, setLang } = useLang()
  const store = useRecoilValue(atoms.store)
  const [ screen, setScreen ] = useRecoilState(atoms.screen)

  useEffect(() => {
    console.log('app settings', { lang, store })
  }, [lang, store])

  return (
    <div className='app-parent-container'>
      <MobileBar />
      <Div className='app-main-container p-0 h-full'>
        <Div iff={screen===Screen.Welcome}>
          <WelcomeScreen demoMode={demoMode}></WelcomeScreen>
        </Div>
        <Div iff={screen===Screen.Dashboard}
          className='app-dashboard-container'
        >
          <DashboardScreen demoMode={demoMode}></DashboardScreen>
        </Div>
        <Div iff={screen===Screen.Settings}>
          <SettingsScreen demoMode={demoMode}></SettingsScreen>
        </Div>
      </Div>
    </div>
  )
}

export default AppComponent
