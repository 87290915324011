import React, { useCallback, useState } from 'react'
import { Screen } from 'lib/enum'
import { useRecoilState } from 'recoil'
import * as atoms from 'lib/atoms'
import { StaticImage } from 'gatsby-plugin-image'
import SettingsArrow from '../../assets/settingsArrow.svg'
import UpArrow from '../../assets/upArrow.svg'

type DropdownMenuProps = {
  menu: 'Dashboard' | 'Settings' | 'Welcome'
}

const DropdownMenuComponent = ({menu}: DropdownMenuProps) => {

  const [ screen, setScreen ] = useRecoilState(atoms.screen)

  return (
    <div className='group'>
      <button type='button' className='flex gap-4 items-center'>
        <h2 className='text-2xl'>{menu}</h2>
        <div className='group-hover:hidden'>
          <SettingsArrow />
        </div>
        <div className='hidden group-hover:block'>
          <UpArrow />
        </div>
      </button>
      <div className='group-hover:block hidden absolute rounded-xl shadow-xl bg-white pt-6 z-50'>
        <div className=''>
          <button type='button' onClick={() => setScreen(menu === 'Settings' ? Screen.Welcome : menu === 'Dashboard' ? Screen.Welcome : Screen.Dashboard)}
              className='hover:bg-black hover:rounded-lg hover:font-semibold px-8 py-4 hover:text-white active:bg-slate-400'>
            {
              menu === 'Settings'  ? 'Welcome'
            : menu === 'Dashboard' ? 'Welcome'
            : 'Dashboard'
            }
          </button>
          <br />
          <button type='button' onClick={() => setScreen(menu==='Settings'? Screen.Dashboard: Screen.Settings)}
              className='hover:bg-black hover:rounded-lg hover:font-semibold px-8 py-4 hover:text-white active:bg-slate-400 w-full'>
            {menu==='Settings'? 'Dashboard': 'Settings'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DropdownMenuComponent
