import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

type ConnectCardLineProps = {
  title: string
  label: string
  text: string
}

const ConnectCardLine = ({
  title,
  label,
  text,
}:ConnectCardLineProps) => {

  const renderImage = () => {
    return title==='username' ? (
      <StaticImage
        src='../../../images/settings/username.svg'
        alt={title}
        width={25}
        height={25}
      />
    ): title==='accountID' ? (
      <StaticImage src='../../../images/settings/accountID.svg'
        alt='accountID'
        placeholder='blurred'
        width={25}
        height={23}
      />
    ): title==='active' ? (
      <StaticImage src='../../../images/settings/active.svg'
        alt='active'
        placeholder='blurred'
        width={25}
        height={27}
      />
    ): title==='secondaryID' ? (
      <StaticImage src='../../../images/settings/accountID.svg'
        alt='secondaryID'
        placeholder='blurred'
        width={25}
        height={23}
      />
    ):(
      <StaticImage
        src='../../../images/settings/account.svg'
        alt={title}
        width={25}
        height={25}
      />
    )
  }

  return (
    <div className='flex justify-between border-b-2 py-8 items-center text-sm lg:text-base'>
      <div className='flex gap-3 items-center'>
        {renderImage()}
        <p>{label}</p>
      </div>
      <p className='integrationInfoText'>
        {text}
      </p>
    </div>
  )
}

export default ConnectCardLine
