import React from 'react'
import { useRecoilValue } from 'recoil'
import { AIDAS } from '@sh/constants/aidas'
import * as atoms from 'lib/atoms'
import { DisplayText } from '@shopify/polaris'

type MetricHeadingProps = {
  children: React.ReactNode
  metric: 'Attention'|'Interest'|'Desire'|'Action'|'Satisfaction'
  text: string
}

const MetricHeading = ({children, metric, text}:MetricHeadingProps) => {
  const lang = useRecoilValue(atoms.lang)

  const getTitle = () => AIDAS[metric][lang].name
  const getLetter = () => AIDAS[metric][lang].letter
  const getMetric = () => AIDAS[metric][lang].metric

  return (
    <div className='px-6 pb-10 pt-20 lg:pt-24 md:pl-8 lg:px-6'>
      {/* <div>
        <DisplayText size='small'>{getTitle()}</DisplayText>
        </div>
        <div>
        <span>{getMetric()}</span>
      </div> */}
      <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
        <h1 className='text-gray-800 font-bold text-3xl xl:pl-2 whitespace-nowrap lg:pr-16'>
          {lang==='JA' ? `${metric} ー ${getTitle()}` : `${getTitle()}`}
          {/* <span style={{fontSize:188}}>
          {children}
        </span> */}
        </h1>
        <p className='pt-4 lg:pt-0 pr-6'>
          {text}
        </p>
      </div>
    </div>
  )
}

export default MetricHeading
