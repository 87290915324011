import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import topMobileImage from '../../../images/welcome/welcome_mobile.svg'
import topDeskTopImage from '../../../images/welcome/welcome_desktop.svg'

type WelcomeTopImageProps = {
}

const WelcomeTopImageComponent = () => {
  return (
    <>
      <div className='absolute z-0 object-contain top-32 sm:block hidden xl:top-20 2xl:hidden'>
        <StaticImage src='../../../images/welcome/welcome_line_desktop.svg'
          alt='line'
          placeholder='blurred'
          width={1313}
          height={837}
          quality={100}
        />
      </div>
      <div className='-mt-10 relative sm:-mt-32 z-10'>
        {/* <StaticImage src='../../../images/welcome/welcome_mobile.svg'
          alt='aidas app can make your advertisement visualise by graphs.'
          placeholder='blurred'
          width={375}
          height={502}
          quality={100}
        /> */}
        <img src={topMobileImage} alt='aidas app can make your advertisement visualise by graphs.'
          width='375' height='502' className='block sm:hidden w-full bg-welcomeBgLineMobile bg-no-repeat bg-contain z-0 pt-16' />
        <img src={topDeskTopImage} alt='aidas app can make your advertisement visualise by graphs.'
          width='1010' height='782' className='sm:block hidden px-8 xl:w-full xl:px-20 2xl:px-40 2xl:bg-welcomeBgLine bg-cover z-0 relative sm:pt-16 md:pt-0 2xl:pt-52 2xl:-top-52' />
      </div>
    </>
  )
}

export default WelcomeTopImageComponent
