import React, { useCallback, useState } from 'react'
import { useRecoilState } from 'recoil'
import * as atoms from 'lib/atoms'
import { Icon } from '@shopify/polaris'
import SelectDateComponent from '../infobar/SelectDate'
import DashboardSettings from './DashboardSettings'
import { SettingsMajor, CalendarMajor } from '@shopify/polaris-icons'
import { Div } from 'components/ui'
import DropdownMenu from 'components/ui/DropdownMenu'

type DashboardNavProps = {
}

const DashboardNavComponent = () => {

  const [selectSettings, setSelectSettings] = useRecoilState(atoms.selectSettings)
  const [selectMobileDate, setSelectMobileDate] = useRecoilState(atoms.selectMobileDate)
  const [active, setActive] = useState(false)


  const handleSelectSettings = useCallback(() => {
    setSelectSettings((selectSettings) => !selectSettings)
  }, [setSelectSettings])

  const handleSelectDate = useCallback(() => {
    setSelectMobileDate((selectMobileDate) => !selectMobileDate)
    setActive((active) => !active)
    // only run once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Div className='flex justify-between items-center px-4 sm:px-16 py-6 sm:py-8 border-b border-gray-100 bg-white fixed z-40 w-full'
      mdScreen='pr-[26rem]'
      xlScreen='w-[calc(100vw-230px)] pr-16'
      xl2Screen='w-[calc(100vw-240px)]'
    >
      <DropdownMenu menu='Dashboard' />
      <div className='flex flex-row h-full gap-6 sm:gap-12 items-center'>
        <SelectDateComponent />
        {selectSettings ?
          <DashboardSettings />
            :
          <>
            <button type='button' onClick={handleSelectDate} className='sm:hidden'>
              <Icon source={CalendarMajor} />
            </button>
            <button type='button' onClick={handleSelectSettings}>
              <Icon source={SettingsMajor} />
            </button>
          </>}
      </div>
    </Div>
  )
}

export default DashboardNavComponent
