import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import TopAttentionGroup from './TopAttentionGroup'
import TopInterestGroup from './TopInterestGroup'
import TopDesireGroup from './TopDesireGroup'
import TopActionGroup from './TopActionGroup'
import TopSatisfactionGroup from './TopSatisfactionGroup'

const TopCardGroup = () => {
  const indicatorStyles= {
    width: 13,
    height: 13,
    display: 'inline-block',
    margin: '0 8px',
  }

  return (
    <>
      <div className='flex flex-col lg:flex-row pt-20 lg:hidden'>
        <Carousel
          showThumbs={false}
          centerMode={true}
          infiniteLoop={true}
          centerSlidePercentage={70}
          showStatus={false}
          emulateTouch={true}
          swipeable={true}
          showArrows={true}
          preventMovementUntilSwipeScrollTolerance={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ ...indicatorStyles }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
             )
           }
           return (
             <li
               style={indicatorStyles}
               onClick={onClickHandler}
               onKeyDown={onClickHandler}
               value={index}
               key={index}
               role='tab'
               tabIndex={0}
               title={`${label} ${index + 1}`}
               aria-label={`${label} ${index + 1}`}
               />
               )
             }}
        >
          <TopAttentionGroup />
          <TopInterestGroup />
          <TopDesireGroup />
          <TopActionGroup />
          <TopSatisfactionGroup />
        </Carousel>
      </div>
      <div className='hidden lg:flex xl:mx-6 2xl:gap-12 lg:justify-center lg:gap-2 lg:mx-4 xl:gap-4 2xl:max-w-screen-lg 2xl:mx-auto'>
        <TopAttentionGroup />
        <TopInterestGroup />
        <TopDesireGroup />
        <TopActionGroup />
        <TopSatisfactionGroup />
      </div>
    </>
  )
}

export default TopCardGroup
