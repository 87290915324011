import React, { useMemo } from 'react'
import { Select } from '@shopify/polaris'
import { serviceNames } from 'lib/const/humanReadableNames'
import useLang from 'lib/hooks/useLang'
import GoogleFigmaIcon from '../../../assets/google-figma30x30.svg'
import FacebookFigmaIcon from '../../../assets/fb-figma30x30.svg'
import YjpFigmaIcon from '../../../assets/yjp-figma30x30.svg'
import { Integration } from '@sh/enums'

interface SelectServiceParams {
  onSelectChange: (val:any) => void
  selected: string
  label: string
  services:Integration[]
}
const SelectServiceOptions = ({ onSelectChange, selected, label, services }:SelectServiceParams) => {
  const { lang } = useLang()

  const icons = {
    [Integration.GOOGLE]: <GoogleFigmaIcon />,
    [Integration.GA]: <GoogleFigmaIcon />,
    [Integration.YJP]: <YjpFigmaIcon />,
    [Integration.FACEBOOK]:<FacebookFigmaIcon />,
  }

  const options = useMemo(() => {
    const temp = [
      // { prefix: <TransparentSquareIcon />, label: 'Select Service', value: '' }
    ]
    Object.keys(serviceNames)
      .forEach((key) => {
        if (services.includes(Integration.GA) && key===Integration.GA) {
          return
        }
        temp.push({
          prefix: icons[key],
          label: `${serviceNames[key][lang]}`,
          value: key,
        })
      })
    return temp
  }, [lang, services])

  return (
    <>
      <Select
        label={label}
        options={options}
        onChange={onSelectChange}
        value={selected}
      />
    </>
  )
}

export default SelectServiceOptions