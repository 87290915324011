import { useCallback, useMemo } from 'react'
import { round, sum } from '@sh/lib/calc'
import { Integration } from '@sh/enums'
import { AdData, EComData, GAData } from '@sh/models/classes'
import * as atoms from 'lib/atoms'
import { LoadingStatus } from 'lib/enum'
import { useRecoilState } from 'recoil'
import { NivoData } from 'lib/types/nivoData'

type IntegrationBreakdown = {
  id: string
  value: number
  change: number
}

type MetricsBreakdown = {
  value:    number
  rateNum:  number
  rateDen:  number
  rate:     number
  desc: {
    value:    string
    rateNum:  string
    rateDen:  string
    rate:     string
  }
  last: {
    value:    number
    rateNum:  number
    rateDen:  number
    rate:     number
  }
  change: {
    value: number
    rate:  number
  }
  changePercent: {
    value: number
    rate:  number
  }
}

const nz = (n:number):number => n || 0

const useDashboardData = () => {

  const [ loadingData, setLoadingData ] = useRecoilState(atoms.loadingData)

  const [ shopifyTotal, setShopifyTotal ] = useRecoilState(atoms.shopifyOverview)
  const [ shopifyTotalLast, setShopifyTotalLast ] = useRecoilState(atoms.shopifyOverviewLast)
  const [ shopifyChannels, setShopifyChannels ] = useRecoilState(atoms.shopifyChannels)
  const [ shopifyChannelsLast, setShopifyChannelsLast ] = useRecoilState(atoms.shopifyChannelsLast)

  const [ fbTotal, setFBTotal ] = useRecoilState(atoms.fbOverview)
  const [ fbTotalLast, setFBTotalLast ] = useRecoilState(atoms.fbOverviewLast)
  const [ fbCampaigns, setFBCampaigns ] = useRecoilState(atoms.fbCampaigns)
  const [ fbCampaignsLast, setFBCampaignsLast ] = useRecoilState(atoms.fbCampaignsLast)

  const [ googleTotal, setGoogleTotal ] = useRecoilState(atoms.googleOverview)
  const [ googleTotalLast, setGoogleTotalLast ] = useRecoilState(atoms.googleOverviewLast)
  const [ googleCampaigns, setGoogleCampaigns ] = useRecoilState(atoms.googleCampaigns)
  const [ googleCampaignsLast, setGoogleCampaignsLast ] = useRecoilState(atoms.googleCampaignsLast)

  const [ yjpTotal, setYJPTotal ] = useRecoilState(atoms.yjpOverview)
  const [ yjpTotalLast, setYJPTotalLast ] = useRecoilState(atoms.yjpOverviewLast)
  const [ yjpCampaigns, setYJPCampaigns ] = useRecoilState(atoms.yjpCampaigns)
  const [ yjpCampaignsLast, setYJPCampaignsLast ] = useRecoilState(atoms.yjpCampaignsLast)

  const [ gaTotal, setGATotal ] = useRecoilState(atoms.gaOverview)
  const [ gaTotalLast, setGATotalLast ] = useRecoilState(atoms.gaOverviewLast)
  const [ gaDataChannels, setGADataChannels ] = useRecoilState(atoms.gaChannels)
  const [ gaDataChannelsLast, setGADataChannelsLast ] = useRecoilState(atoms.gaChannelsLast)

  const resetCurrentDashboardData = () => {
    setLoadingData(prev => ({
      ...prev,
      shopify: {
        total: LoadingStatus.NotStarted,
        totalCompare: prev.shopify.totalCompare,
        channels: LoadingStatus.NotStarted,
        channelsCompare: prev.shopify.channelsCompare,
      },
      facebook: {
        total: LoadingStatus.NotStarted,
        totalCompare: prev.facebook.totalCompare,
        campaigns: LoadingStatus.NotStarted,
        campaignsCompare: prev.facebook.campaignsCompare,
      },
      google: {
        total: LoadingStatus.NotStarted,
        totalCompare: prev.google.totalCompare,
        campaigns: LoadingStatus.NotStarted,
        campaignsCompare: prev.google.campaignsCompare,
      },
      yjp: {
        total: LoadingStatus.NotStarted,
        totalCompare: prev.yjp.totalCompare,
        campaigns: LoadingStatus.NotStarted,
        campaignsCompare: prev.yjp.campaignsCompare,
      },
      ga: {
        total: LoadingStatus.NotStarted,
        totalCompare: prev.ga.totalCompare,
        channels: LoadingStatus.NotStarted,
        channelsCompare: prev.ga.channelsCompare,
      },
    }))
  }
  const resetComparisonDashboardData = () => {
    setLoadingData(prev => ({
      ...prev,
      shopify: {
        total: prev.shopify.total,
        totalCompare: LoadingStatus.NotStarted,
        channels: prev.shopify.channels,
        channelsCompare: LoadingStatus.NotStarted,
      },
      facebook: {
        total: prev.facebook.total,
        totalCompare: LoadingStatus.NotStarted,
        campaigns: prev.facebook.campaigns,
        campaignsCompare: LoadingStatus.NotStarted,
      },
      google: {
        total: prev.google.total,
        totalCompare: LoadingStatus.NotStarted,
        campaigns: prev.google.campaigns,
        campaignsCompare: LoadingStatus.NotStarted,
      },
      yjp: {
        total: prev.yjp.total,
        totalCompare: LoadingStatus.NotStarted,
        campaigns: prev.yjp.campaigns,
        campaignsCompare: LoadingStatus.NotStarted,
      },
      ga: {
        total: prev.ga.total,
        totalCompare: LoadingStatus.NotStarted,
        channels: prev.ga.channels,
        channelsCompare: LoadingStatus.NotStarted,
      },
    }))
  }

  const _setLoadingCompleted = (integration:Integration, isTotals:boolean, isMainPeriod=true) => {
    const _match = (intg:Integration, tot:boolean, cur:boolean):boolean => intg===integration
      && tot===isTotals
      && cur===isMainPeriod

    setLoadingData(prev => ({
      ...prev,
      shopify: {
        total: _match(Integration.SHOPIFY, true, true) ? LoadingStatus.Completed : prev.shopify.total,
        totalCompare: _match(Integration.SHOPIFY, true, false) ? LoadingStatus.Completed : prev.shopify.total,
        channels: _match(Integration.SHOPIFY, false, true) ? LoadingStatus.Completed : prev.shopify.channels,
        channelsCompare: _match(Integration.SHOPIFY, false, true) ? LoadingStatus.Completed : prev.shopify.channelsCompare,
      },
      facebook: {
        total: _match(Integration.FACEBOOK, true, true) ? LoadingStatus.Completed : prev.facebook.total,
        totalCompare: _match(Integration.FACEBOOK, true, false) ? LoadingStatus.Completed : prev.facebook.total,
        campaigns: _match(Integration.FACEBOOK, false, true) ? LoadingStatus.Completed : prev.facebook.campaigns,
        campaignsCompare: _match(Integration.FACEBOOK, false, true) ? LoadingStatus.Completed : prev.facebook.campaignsCompare,
      },
      google: {
        total: _match(Integration.GOOGLE, true, true) ? LoadingStatus.Completed : prev.google.total,
        totalCompare: _match(Integration.GOOGLE, true, false) ? LoadingStatus.Completed : prev.google.total,
        campaigns: _match(Integration.GOOGLE, false, true) ? LoadingStatus.Completed : prev.google.campaigns,
        campaignsCompare: _match(Integration.GOOGLE, false, true) ? LoadingStatus.Completed : prev.google.campaignsCompare,
      },
      yjp: {
        total: _match(Integration.YJP, true, true) ? LoadingStatus.Completed : prev.yjp.total,
        totalCompare: _match(Integration.YJP, true, false) ? LoadingStatus.Completed : prev.yjp.total,
        campaigns: _match(Integration.YJP, false, true) ? LoadingStatus.Completed : prev.yjp.campaigns,
        campaignsCompare: _match(Integration.YJP, false, true) ? LoadingStatus.Completed : prev.yjp.campaignsCompare,
      },
      ga: {
        total: _match(Integration.GA, true, true) ? LoadingStatus.Completed : prev.ga.total,
        totalCompare: _match(Integration.GA, true, false) ? LoadingStatus.Completed : prev.ga.total,
        channels: _match(Integration.GA, false, true) ? LoadingStatus.Completed : prev.ga.channels,
        channelsCompare: _match(Integration.GA, false, true) ? LoadingStatus.Completed : prev.ga.channelsCompare,
      },
    }))
  }

  const updateShopifyTotal = (d:EComData, isMainPeriod:boolean) => {
    if (isMainPeriod) {
      setShopifyTotal(d)
      _setLoadingCompleted(Integration.SHOPIFY, true, true)
    } else {
      setShopifyTotalLast(d)
      _setLoadingCompleted(Integration.SHOPIFY, true, false)
    }
  }

  const updateShopifyChannels = (ds:EComData[], isMainPeriod:boolean) => {
    if (isMainPeriod) {
      setShopifyChannels(ds)
      _setLoadingCompleted(Integration.SHOPIFY, false, true)
    } else {
      setShopifyChannelsLast(ds)
      _setLoadingCompleted(Integration.SHOPIFY, false, false)
    }
  }

  const updateAdTotal = (integration:Integration, d:AdData, isMainPeriod:boolean) => {
    if (!d?.spend) {
      _setLoadingCompleted(integration, true, isMainPeriod)
      return
    }
    switch (integration) {
      case Integration.FACEBOOK: {
        if (isMainPeriod) {
          setFBTotal(d)
        } else {
          setFBTotalLast(d)
        }
        break
      }
      case Integration.GOOGLE: {
        if (isMainPeriod) {
          setGoogleTotal(d)
        } else {
          setGoogleTotalLast(d)
        }
        break
      }
      case Integration.YJP: {
        if (isMainPeriod) {
          setYJPTotal(d)
        } else {
          setYJPTotalLast(d)
        }
        break
      }
      default: {
        console.log('Programming Error: Invalid integration value passed', integration)
      }
    }
    _setLoadingCompleted(integration, true, isMainPeriod)
  }

  const updateAdCampaigns = (integration:Integration, ds:AdData[], isMainPeriod:boolean) => {
    if (!ds?.length) {
      _setLoadingCompleted(integration, false, isMainPeriod)
      return
    }
    switch (integration) {
      case Integration.FACEBOOK: {
        if (isMainPeriod) {
          setFBCampaigns(ds)
        } else {
          setFBCampaignsLast(ds)
        }
        break
      }
      case Integration.GOOGLE: {
        if (isMainPeriod) {
          setGoogleCampaigns(ds)
        } else {
          setGoogleCampaignsLast(ds)
        }
        break
      }
      case Integration.YJP: {
        if (isMainPeriod) {
          setYJPCampaigns(ds)
        } else {
          setYJPCampaignsLast(ds)
        }
        break
      }
      default: {
        console.log('Programming Error: Invalid integration code passed', integration)
      }
    }
    _setLoadingCompleted(integration, false, isMainPeriod)
  }

  const updateGaTotal = (d:GAData, isMainPeriod:boolean) => {
    if (!d?.sessions) {
      _setLoadingCompleted(Integration.GA, true, isMainPeriod)
      return
    }
    if (isMainPeriod) {
      setGATotal(d)
    } else {
      setGATotalLast(d)
    }
    _setLoadingCompleted(Integration.GA, true, isMainPeriod)
  }

  const updateGAChannels = (ds:GAData[], isMainPeriod:boolean) => {
    if (!ds?.length) {
      _setLoadingCompleted(Integration.GA, false, isMainPeriod)
      return
    }
    if (isMainPeriod) {
      setGADataChannels(ds)
    } else {
      setGADataChannelsLast(ds)
    }
    _setLoadingCompleted(Integration.GA, false, isMainPeriod)
  }

  /** LOADING PROGRESS */

  const publisherLoadingProgress = ():number => {
    const fbVal = loadingData.facebook.campaigns===LoadingStatus.Completed
      ? 34
      : 0
    const gVal = loadingData.google.campaigns===LoadingStatus.Completed
      ? 33
      : 0
    const yjpVal = loadingData.yjp.campaigns===LoadingStatus.Completed
      ? 33
      : 0
    return fbVal + gVal + yjpVal
  }

  const gaLoadingProgress = ():number => {
    const tot = loadingData.ga.total===LoadingStatus.Completed
      ? 50
      : 0
    const tot2 = loadingData.ga.totalCompare===LoadingStatus.Completed
      ? 50
      : 0
    return tot+tot2
  }

  const shopifyLoadingProgress = ():number => {
    const tot = loadingData.shopify.total===LoadingStatus.Completed
      ? 50
      : 0
    const tot2 = loadingData.shopify.totalCompare===LoadingStatus.Completed
      ? 50
      : 0
    return tot+tot2
  }

  const shopifyChannelsLoadingProgress = ():number => {
    const tot = loadingData.shopify.channels===LoadingStatus.Completed
      ? 50
      : 0
    const tot2 = loadingData.shopify.channelsCompare===LoadingStatus.Completed
      ? 50
      : 0
    return tot+tot2
  }

  const gaChannelsProgress = (): boolean => {
    const tot = loadingData.ga.channels===LoadingStatus.Completed
    ? 50
    : 0
    const tot2 = loadingData.ga.channelsCompare===LoadingStatus.Completed
    ? 50
    : 0
    return (tot + tot2)!==100
  }

  /** CALCULATIONS */

  const spendTotal = useCallback(() => {
    const s = nz(fbTotal?.spend) + nz(googleTotal?.spend) + nz(yjpTotal?.spend)
    return s
  }, [fbTotal?.spend, googleTotal?.spend, yjpTotal?.spend])
  const spendTotalLast = useCallback(() => {
    const s = nz(fbTotalLast?.spend) + nz(googleTotalLast?.spend) + nz(yjpTotalLast?.spend)
    return s
  }, [fbTotalLast?.spend, googleTotalLast?.spend, yjpTotalLast?.spend])

  const _calcCPM = (impressions:number, spend:number) => {
    const impressionK = impressions/1000
    return impressions ? round(spend/impressionK, 2) : 0
  }
  const _calcCTR = (clicks:number, impressions:number) => {
    return impressions ? round(clicks/impressions, 2) : 0
  }
  const _calcCPC = (spend:number, clicks:number) => {
    return clicks ? round(spend/clicks, 2) : 0
  }
  const _calcER = (engagements:number, sessions:number) => {
    return sessions ? round(engagements/sessions, 2) : 0
  }
  const _calcCR = (conversions:number, opportunities:number) => {
    return opportunities ? conversions/opportunities : 0
  }
  const _calcSR = (returningCustomers:number, customers:number) => {
    return customers ? round(returningCustomers/customers, 2) : 0
  }
  const _calcReturningPercent = (newOrders:number, returningOrders:number) => {
    const orders = newOrders+returningOrders
    if (!orders) return 0
    return round(returningOrders/orders, 2)
  }
  const _calcAOV = (sales:number, orders:number) => {
    return orders ? round(sales/orders, 2) : 0
  }

  /** Attention Calcs */

  const _calcAttentionData = useCallback((
    impressions:number,
    impressionsLast:number,
    spend:number,
    spendLast:number,
  ):MetricsBreakdown => {
    const breakdown:MetricsBreakdown = {
      value:    impressions,
      rateNum:  spend,
      rateDen:  impressions,
      rate:     _calcCPM(impressions, spend),
      desc: {
        value:    'impressions',
        rateNum:  '1000 impressions',
        rateDen:  'spend',
        rate:     'CPM',
      },
      last: {
        value:    impressionsLast,
        rateNum:  spendLast,
        rateDen:  impressionsLast,
        rate:     _calcCPM(impressionsLast, spendLast),
      },
      change: { value:0, rate:0 },
      changePercent: { value:0, rate:0 },
    }

    breakdown.change.value = breakdown.value - breakdown.last.value
    breakdown.change.rate  = breakdown.rate  - breakdown.last.rate
    breakdown.changePercent.value = breakdown.value/breakdown.last.value - 1
    breakdown.changePercent.rate  = breakdown.rate/breakdown.last.rate - 1

    return breakdown
  }, [])

  const attentionData = useMemo<MetricsBreakdown>(() => {
    return _calcAttentionData(
      nz(fbTotal?.impressions) + nz(googleTotal?.impressions) + nz(yjpTotal?.impressions),
      nz(fbTotalLast?.impressions) + nz(googleTotalLast?.impressions) + nz(yjpTotalLast?.impressions),
      spendTotal(),
      spendTotalLast(),
    )
  }, [
    _calcAttentionData, spendTotal, spendTotalLast,
    fbTotal?.impressions, googleTotal?.impressions, yjpTotal?.impressions, fbTotalLast?.impressions, googleTotalLast?.impressions, yjpTotalLast?.impressions,
  ])

  const attentionFBData = useMemo<MetricsBreakdown>(() => {
    return _calcAttentionData(
      nz(fbTotal?.impressions),
      nz(fbTotalLast?.impressions),
      nz(fbTotal?.spend),
      nz(fbTotalLast?.spend),
    )
  }, [_calcAttentionData, fbTotal?.impressions, fbTotalLast?.impressions, fbTotal?.spend, fbTotalLast?.spend])
  const attentionGData = useMemo<MetricsBreakdown>(() => {
    return _calcAttentionData(
      nz(googleTotal?.impressions),
      nz(googleTotalLast?.impressions),
      nz(googleTotal?.spend),
      nz(googleTotalLast?.spend),
    )
  }, [_calcAttentionData, googleTotal?.impressions, googleTotalLast?.impressions, googleTotal?.spend, googleTotalLast?.spend])
  const attentionYJPData = useMemo<MetricsBreakdown>(() => {
    return _calcAttentionData(
      nz(yjpTotal?.impressions),
      nz(yjpTotalLast?.impressions),
      nz(yjpTotal?.spend),
      nz(yjpTotalLast?.spend),
    )
  }, [_calcAttentionData, yjpTotal?.impressions, yjpTotalLast?.impressions, yjpTotal?.spend, yjpTotalLast?.spend])

  /** Interest Calcs */

  const _calcInterestData = useCallback((
    clicks:number,
    clicksLast:number,
    spend:number,
    spendLast:number,
  ):MetricsBreakdown => {
    const breakdown:MetricsBreakdown = {
      value:    clicks,
      rateNum:  spend,
      rateDen:  clicks,
      rate:     _calcCPC(spend, clicks),
      desc: {
        value:    'clicks',
        rateNum:  'spend',
        rateDen:  'clicks',
        rate:     'CPC',
      },
      last: {
        value:    clicksLast,
        rateNum:  spendLast,
        rateDen:  clicksLast,
        rate:     _calcCPC(spendLast, clicksLast),
      },
      change: { value:0, rate:0 },
      changePercent: { value:0, rate:0 },
    }

    breakdown.change.value = breakdown.value - breakdown.last.value
    breakdown.change.rate  = breakdown.rate  - breakdown.last.rate
    breakdown.changePercent.value = breakdown.value/breakdown.last.value - 1
    breakdown.changePercent.rate  = breakdown.rate/breakdown.last.rate - 1

    return breakdown
  }, [])

  const interestData = useMemo<MetricsBreakdown>(() => {
    return _calcInterestData(
      nz(fbTotal?.clicks) + nz(googleTotal?.clicks) + nz(yjpTotal?.clicks),
      nz(fbTotalLast?.clicks) + nz(googleTotalLast?.clicks) + nz(yjpTotalLast?.clicks),
      spendTotal(),
      spendTotalLast(),
    )
  }, [
    _calcInterestData, spendTotal, spendTotalLast,
    fbTotal?.clicks, googleTotal?.clicks, yjpTotal?.clicks, fbTotalLast?.clicks, googleTotalLast?.clicks, yjpTotalLast?.clicks,
  ])

  const interestFBData = useMemo<MetricsBreakdown>(() => {
    return _calcInterestData(
      nz(fbTotal?.clicks),
      nz(fbTotalLast?.clicks),
      nz(fbTotal?.spend),
      nz(fbTotalLast?.spend),
    )
  }, [_calcInterestData, fbTotal?.clicks, fbTotalLast?.clicks, fbTotal?.spend, fbTotalLast?.spend])
  const interestGData = useMemo<MetricsBreakdown>(() => {
    return _calcInterestData(
      nz(googleTotal?.clicks),
      nz(googleTotalLast?.clicks),
      nz(googleTotal?.spend),
      nz(googleTotalLast?.spend),
    )
  }, [_calcInterestData, googleTotal?.clicks, googleTotalLast?.clicks, googleTotal?.spend, googleTotalLast?.spend])
  const interestYJPData = useMemo<MetricsBreakdown>(() => {
    return _calcInterestData(
      nz(yjpTotal?.clicks),
      nz(yjpTotalLast?.clicks),
      nz(yjpTotal?.spend),
      nz(yjpTotalLast?.spend),
    )
  }, [_calcInterestData, yjpTotal?.clicks, yjpTotalLast?.clicks, yjpTotal?.spend, yjpTotalLast?.spend])

  /** Desire Calcs */

  const _calcDesireData = useCallback((
    engagements:number,
    engagementsLast:number,
    sessions:number,
    sessionsLast:number,
  ):MetricsBreakdown => {
    const breakdown:MetricsBreakdown = {
      value:    engagements,
      rateNum:  engagements,
      rateDen:  sessions,
      rate:     _calcER(engagements, sessions),
      desc: {
        value:    'engagements',
        rateNum:  'engagements',
        rateDen:  'sessions',
        rate:     'Engagement Rate',
      },
      last: {
        value:    sessionsLast,
        rateNum:  engagementsLast,
        rateDen:  sessionsLast,
        rate:     _calcER(engagementsLast, sessionsLast),
      },
      change: { value:0, rate:0 },
      changePercent: { value:0, rate:0 },
    }

    breakdown.change.value = breakdown.value - breakdown.last.value
    breakdown.change.rate  = breakdown.rate  - breakdown.last.rate
    breakdown.changePercent.value = breakdown.value/breakdown.last.value - 1
    breakdown.changePercent.rate  = breakdown.rate/breakdown.last.rate - 1

    return breakdown
  }, [])

  const desireData = useMemo<MetricsBreakdown>(() => {
    return _calcDesireData(
      gaTotal?.engagedSessions,
      gaTotalLast?.engagedSessions,
      gaTotal?.sessions,
      gaTotalLast?.sessions,
    )
  }, [_calcDesireData, gaTotal?.engagedSessions, gaTotalLast?.engagedSessions, gaTotal?.sessions, gaTotalLast?.sessions])

  /** Action Calcs */

  const _calcActionData = useCallback((
    orders:number,
    ordersLast:number,
    sessions:number,
    sessionsLast:number,
  ):MetricsBreakdown => {
    const breakdown:MetricsBreakdown = {
      value:    orders,
      rateNum:  orders,
      rateDen:  sessions,
      rate:     _calcCR(orders, sessions),
      desc: {
        value:    'orders',
        rateNum:  'orders',
        rateDen:  'sessions',
        rate:     'Conversion Rate',
      },
      last: {
        value:    ordersLast,
        rateNum:  ordersLast,
        rateDen:  sessionsLast,
        rate:     _calcCR(ordersLast, sessionsLast),
      },
      change: { value:0, rate:0 },
      changePercent: { value:0, rate:0 },
    }

    breakdown.change.value = breakdown.value - breakdown.last.value
    breakdown.change.rate  = breakdown.rate  - breakdown.last.rate
    breakdown.changePercent.value = breakdown.value/breakdown.last.value - 1
    breakdown.changePercent.rate  = breakdown.rate/breakdown.last.rate - 1

    return breakdown
  }, [])

  const actionData = useMemo<MetricsBreakdown>(() => {
    return _calcActionData(
      shopifyTotal?.orders.new,
      shopifyTotalLast?.orders.new,
      gaTotal?.sessions,
      gaTotalLast?.sessions,
    )
  }, [_calcActionData, shopifyTotal?.orders.new, shopifyTotalLast?.orders.new, gaTotal?.sessions, gaTotalLast?.sessions])

  /** Satisfaction Calcs */

  const _calcSatisfactionData = useCallback((
    newOrders:number,
    newOrdersLast:number,
    reorders:number,
    reordersLast:number,
  ):MetricsBreakdown => {
    const breakdown:MetricsBreakdown = {
      value:    reorders,
      rateNum:  reorders,
      rateDen:  reorders+newOrders,
      rate:     _calcReturningPercent(newOrders, reorders),
      desc: {
        value:    'reorders',
        rateNum:  'reorders',
        rateDen:  'total orders',
        rate:     'Returning Percent',
      },
      last: {
        value:    reordersLast,
        rateNum:  reordersLast,
        rateDen:  reordersLast+newOrdersLast,
        rate:     _calcReturningPercent(newOrdersLast, reordersLast),
      },
      change: { value:0, rate:0 },
      changePercent: { value:0, rate:0 },
    }

    breakdown.change.value = breakdown.value - breakdown.last.value
    breakdown.change.rate  = breakdown.rate  - breakdown.last.rate
    breakdown.changePercent.value = breakdown.value/breakdown.last.value - 1
    breakdown.changePercent.rate  = breakdown.rate/breakdown.last.rate - 1

    return breakdown
  }, [])

  const satisfactionData = useMemo<MetricsBreakdown>(() => {
    return _calcSatisfactionData(
      shopifyTotal?.orders.new,
      shopifyTotalLast?.orders.new,
      shopifyTotal?.orders.returning,
      shopifyTotalLast?.orders.returning,
    )
  }, [_calcSatisfactionData, shopifyTotal?.orders.new, shopifyTotalLast?.orders.new, shopifyTotal?.orders.returning, shopifyTotalLast?.orders.returning])

  /** Misc Calcs */
  const gAttentionTotal = () => nz(fbTotal?.impressions) + nz(googleTotal?.impressions) + nz(yjpTotal?.impressions)
  const gAttentionTotalLast = () => nz(fbTotalLast?.impressions) + nz(googleTotalLast?.impressions) + nz(yjpTotalLast?.impressions)
  const gInterestTotal = () => nz(fbTotal?.clicks) + nz(googleTotal?.clicks) + nz(yjpTotal?.clicks)
  const gDesireTotal = () => nz(gaTotal?.engagedSessions)
  const gActionTotal = () => nz(shopifyTotal?.orders.new) + nz(shopifyTotal?.orders.returning)
  const gSatisfactionTotal = () => nz(shopifyTotal?.orders?.returning)

  const gAttentionRate = () => {
    const i = gAttentionTotal()
    const s = spendTotal()
    return _calcCPM(i, s)
  }

  const gInterestRate = () => {
    const cl = gInterestTotal()
    const s = nz(fbTotal?.spend) + nz(googleTotal?.spend) + nz(yjpTotal?.spend)
    return _calcCPC(s, cl)
  }
  const gDesireRate = () => {
    return _calcER(gDesireTotal(), nz(gaTotal?.sessions))*100
  }
  const gActionRate = () => {
    return round(_calcCR(gActionTotal(), nz(gaTotal?.sessions))*100, 2)
  }
  const gSatisfactionRate = () => {
    return _calcReturningPercent(nz(shopifyTotal?.orders.new), nz(shopifyTotal?.orders.returning))*100
  }

  const gAttentionChange = () => {
    const cpm = gAttentionRate()
    const i2 = gAttentionTotalLast()
    const s2 = spendTotalLast()
    const cpm2 = _calcCPM(i2, s2)
    const ch = cpm / cpm2 - 1
    return round(ch*100, 1)
  }
  const gInterestChange = () => {
    const cpc = gInterestRate()
    const c2 = nz(fbTotalLast?.clicks) + nz(googleTotalLast?.clicks) + nz(yjpTotalLast?.clicks)
    const s2 = nz(fbTotalLast?.spend) + nz(googleTotalLast?.spend) + nz(yjpTotalLast?.spend)
    const cpc2 = _calcCPC(s2, c2)
    const ch = cpc / cpc2 - 1
    return round(ch*100, 1)
  }
  const gDesireChange = () => {
    const er = gDesireRate()
    const er2 = _calcER(gaTotalLast?.engagedSessions, gaTotalLast?.sessions)*100
    const ch = er / er2 - 1
    return round(ch*100, 1)
  }
  const gActionChange = () => {
    const cr = gActionRate()
    const cr2 = _calcCR(shopifyTotalLast?.orders?.new+shopifyTotalLast?.orders?.returning, gaTotalLast?.sessions)*100
    const ch = cr / cr2 - 1
    return round(ch*100, 1)
  }
  const gSatisfactionChange = () => {
    const sr = gSatisfactionRate()
    const sr2 = _calcReturningPercent(nz(shopifyTotalLast?.orders.new), nz(shopifyTotalLast?.orders.returning))*100
    const ch = sr / sr2 - 1
    return round(ch*100, 1)
  }

  const gFBCPM = () => round(_calcCPM(nz(fbTotal?.impressions), nz(fbTotal?.spend)), 0)
  const gGoogleCPM = () => round(_calcCPM(nz(googleTotal?.impressions), nz(googleTotal?.spend)), 0)
  const gYJPCPM = () => round(_calcCPM(nz(yjpTotal?.impressions), nz(yjpTotal?.spend)), 0)
  const gFBCPC = () => round(_calcCPC(nz(fbTotal?.spend), nz(fbTotal?.clicks)), 0)
  const gGoogleCPC = () => round(_calcCPC(nz(googleTotal?.spend), nz(googleTotal?.clicks)), 0)
  const gYJPCPC = () => round(_calcCPC(nz(yjpTotal?.spend), nz(yjpTotal?.clicks)), 0)

  const gFBCPMChange = () => {
    const cpm = gFBCPM()
    const cpm2 = round(_calcCPM(nz(fbTotalLast?.impressions), nz(fbTotalLast?.spend)), 0)
    const ch = cpm / cpm2 - 1
    return round(ch*100, 1)
  }
  const gGoogleCPMChange = () => {
    const cpm = gGoogleCPM()
    const cpm2 = round(_calcCPM(nz(googleTotalLast?.impressions), nz(googleTotalLast?.spend)), 0)
    const ch = cpm / cpm2 - 1
    return round(ch*100, 1)
  }
  const gYJPCPMChange = () => {
    const cpm = gYJPCPM()
    const cpm2 = round(_calcCPM(nz(yjpTotalLast?.impressions), nz(yjpTotalLast?.spend)), 0)
    const ch = cpm / cpm2 - 1
    return round(ch*100, 1)
  }
  const gFBCPCChange = () => {
    const cpc = gFBCPC()
    const cpc2 = round(_calcCPC(nz(fbTotalLast?.spend), nz(fbTotalLast?.clicks)), 0)
    const ch = cpc / cpc2 - 1
    return round(ch*100, 1)
  }
  const gGoogleCPCChange = () => {
    const cpc = gGoogleCPC()
    const cpc2 = round(_calcCPC(nz(googleTotalLast?.spend), nz(googleTotalLast?.clicks)), 0)
    const ch = cpc / cpc2 - 1
    return round(ch*100, 1)
  }
  const gYJPCPCChange = () => {
    const cpc = gYJPCPC()
    const cpc2 = round(_calcCPC(nz(yjpTotalLast?.spend), nz(yjpTotalLast?.clicks)), 0)
    const ch = cpc / cpc2 - 1
    return round(ch*100, 1)
  }

  // Data Breakdowns

  const calcImpressionBreakdown = ():NivoData[] => {
    const data = []

    data.push({
      id: 'facebook',
      label: 'Facebook',
      value: fbTotal?.impressions || 0,
    })
    data.push({
      id: 'google',
      label: 'Google',
      value: googleTotal?.impressions || 0,
    })
    data.push({
      id: 'YJP',
      label: 'YJP',
      value: yjpTotal?.impressions || 0,
    })

    // const sumData = sum(data.map(d => d.value))
    return data
      .sort((a,b) => a.value-b.value)
      // .map(d => ({
      //   id: d.id,
      //   label: d.label,
      //   value: Math.floor((d.value / sumData)*100),
      // }))
  }

  const calcClickBreakdown = ():NivoData[] => {
    const data = []

    data.push({
      id: 'facebook',
      label: 'Facebook',
      value: fbTotal?.clicks || 0,
    })
    data.push({
      id: 'google',
      label: 'Google',
      value: googleTotal?.clicks || 0,
    })
    data.push({
      id: 'YJP',
      label: 'YJP',
      value: yjpTotal?.clicks || 0,
    })

    // const sumData = sum(data.map(d => d.value))
    return data
      .sort((a,b) => a.value-b.value)
      // .map(d => ({
      //   id: d.id,
      //   label: d.label,
      //   value: Math.floor((d.value / sumData)*100),
      // }))
  }

  const calcEngagementsBreakdown = ():NivoData[] => {
    if (!gaDataChannels?.length) return []
    // const sumData = sum(gaDataChannels.map(d => d.engagedSessions))
    const data:NivoData[] = gaDataChannels
      .map(ch => ({
        id: ch.description,
        label: ch.description,
        // value: Math.floor((ch.engagedSessions / sumData)*100),
        value: ch.engagedSessions,
      }))
      .sort((a,b) => a.value-b.value)
    return data
  }

  const calcCustomerBreakdown = ():NivoData[] => {
    if (!shopifyChannels?.length) return []
    // const sumData = sum(shopifyChannels.map(d => d.allCustomers()))
    const data:NivoData[] = shopifyChannels
      .map(ch => ({
        id: ch.description,
        label: ch.description,
        // value: Math.floor((ch.allCustomers() / sumData)*100),
        value: ch.allOrders(),
      }))
      .sort((a,b) => a.value-b.value)
    return data
  }

  const calcConversionBreakdown = ():NivoData[] => {
    const data = [
      {
        id: 'facebook',
        label: 'Facebook',
        value: fbTotal?.conversions || 0,
      },{
        id: 'google',
        label: 'Google',
        value: googleTotal?.conversions || 0,
      },{
        id: 'YJP',
        label: 'YJP',
        value: yjpTotal?.conversions || 0,
      },
    ]
    // const sumData = sum(data.map(d => d.value))
    return data
      .map(d => ({
        id: d.id,
        label: d.label,
        value: d.value,
      }))
      .sort((a,b) => a.value-b.value)
  }

  const calcEngagementChangeBreakdown = ():IntegrationBreakdown[] => {
    if (!gaDataChannels?.length) return []
    const data:IntegrationBreakdown[] = []
    const _calcBreakdown = (cur:GAData, last:GAData):IntegrationBreakdown => {
      return {
        id: cur.description?.toString() || '?',
        value: round(cur.engagementRate()*100, 4),
        change: round((cur.engagementRate()/last?.engagementRate() -1)*100, 2),
      }
    }
    const _getTop3 = () => [...gaDataChannels]
      .sort((a,b) => b.sessions - a.sessions)
      .slice(0,3)

    for (const ga of _getTop3()) {
      const last = gaDataChannelsLast?.find(d => d.description===ga.description)
      data.push(_calcBreakdown(ga, last))
    }
    return data
  }

  const calcConversionChangeBreakdown = ():IntegrationBreakdown[] => {
    const data:IntegrationBreakdown[] = []
    const _calcBreakdown = (cur:AdData, last:AdData):IntegrationBreakdown => {
      return {
        id: cur.provider?.toString() || '?',
        value: round(cur?.conversionRate?.()*100, 4),
        change: round((cur?.conversionRate?.()/last?.conversionRate?.() -1)*100, 2),
      }
    }

    if (fbTotal && fbTotalLast) data.push(_calcBreakdown(fbTotal, fbTotalLast))
    if (googleTotal && googleTotalLast) data.push(_calcBreakdown(googleTotal, googleTotalLast))
    if (yjpTotal && yjpTotalLast) data.push(_calcBreakdown(yjpTotal, yjpTotalLast))
    return data
  }

  const calcCustomerChangeBreakdown = ():IntegrationBreakdown[] => {
    if (!shopifyChannels?.length) return []
    const data:IntegrationBreakdown[] = []
    const _calcBreakdown = (cur:EComData, last:EComData):IntegrationBreakdown => {
      return {
        id: cur.description?.toString() || '?',
        value: round(cur.customerReturningRate()*100, 0),
        change: round((
          cur.customerReturningRate()/last?.customerReturningRate() -1
        )*100, 2),
      }
    }
    const _getTop3 = () => [...shopifyChannels]
      .sort((a,b) => b.allRevenue() - a.allRevenue())
      .slice(0,3)

    for (const sh of _getTop3()) {
      const last = shopifyChannelsLast?.find(d => d.description===sh.description)
      data.push(_calcBreakdown(sh, last))
    }
    return data
  }

  const calcGAChannelConversionRate = ():IntegrationBreakdown[] => {
    if (!gaDataChannels?.length) return []

    try {
      const data:IntegrationBreakdown[] = [...gaDataChannels]
        .sort((a,b) => b?.transactions - a?.transactions)
        .map(ch => {
          const value = round((ch?.transactions/ch?.sessions)*100, 2)
          const last = gaDataChannelsLast?.find(ch2 => ch2.description===ch.description)
          const crLast = last
            ? round((last?.transactions/last?.sessions)*100, 2)
            : null
          const change = crLast
            ? round(value/crLast-1,4)*100
            : 0
          return {
            id: ch?.description,
            value,
            change,
          }
        })
      return data
        .slice(0,3)
    } catch (e) {
      console.log('failed convert chanenls', e)
      return []
    }
  }

  return {
    // data sources
    loadingData,
    shopifyTotal,
    shopifyTotalLast,
    shopifyChannels,
    shopifyChannelsLast,
    fbTotal,
    fbTotalLast,
    fbCampaigns,
    fbCampaignsLast,
    googleTotal,
    googleTotalLast,
    googleCampaigns,
    googleCampaignsLast,
    yjpTotal,
    yjpTotalLast,
    yjpCampaigns,
    yjpCampaignsLast,
    gaTotal,
    gaTotalLast,
    gaDataChannels,
    gaDataChannelsLast,
    // update functions
    resetCurrentDashboardData,
    resetComparisonDashboardData,
    updateShopifyTotal,
    updateShopifyChannels,
    updateAdTotal,
    updateAdCampaigns,
    updateGaTotal,
    updateGAChannels,
    // loading progress
    publisherLoadingProgress,
    gaLoadingProgress,
    gaChannelsProgress,
    shopifyLoadingProgress,
    shopifyChannelsLoadingProgress,
    // calculations
    attentionData,
    attentionFBData,
    attentionGData,
    attentionYJPData,
    interestData,
    interestFBData,
    interestGData,
    interestYJPData,
    desireData,
    actionData,
    satisfactionData,

    gAttentionTotal,
    gAttentionTotalLast,
    gInterestTotal,
    gDesireTotal,
    gActionTotal,
    gSatisfactionTotal,
    gAttentionRate,
    gInterestRate,
    gDesireRate,
    gActionRate,
    gSatisfactionRate,
    gAttentionChange,
    gInterestChange,
    gDesireChange,
    gActionChange,
    gSatisfactionChange,
    // calculations for tooltips
    spendTotal,
    spendTotalLast,
    // calculations for ad providers
    gFBCPM,
    gGoogleCPM,
    gYJPCPM,
    gFBCPC,
    gGoogleCPC,
    gYJPCPC,
    gFBCPMChange,
    gGoogleCPMChange,
    gYJPCPMChange,
    gFBCPCChange,
    gGoogleCPCChange,
    gYJPCPCChange,
    // breakdowns
    calcImpressionBreakdown,
    calcClickBreakdown,
    calcEngagementsBreakdown,
    calcCustomerBreakdown,
    calcConversionBreakdown,
    calcEngagementChangeBreakdown,
    calcConversionChangeBreakdown,
    calcCustomerChangeBreakdown,
    calcGAChannelConversionRate,
  }
}

export default useDashboardData
