import React, { useEffect, useMemo, useState } from 'react'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { NivoData } from 'lib/types/nivoData'
import { ProgressBar } from '@shopify/polaris'
import { Div } from 'components/ui'

type TreeMap = {
  data: NivoData[]
  metric: 'Attention'|'Interest'|'Desire'|'Action'|'Satisfaction'
  loadProgress?: number
}

const TreeMap = ({ data, metric, loadProgress=100 }: TreeMap) => {

  const [progressCount, setProgressCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressCount(c => Math.min(c + 1, 100))
    }, 150)
    if(progressCount===90) {
      clearInterval(interval)
    }
    if(loadProgress===100) {
      setProgressCount(100)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [progressCount, loadProgress])

  const colors = metric==='Attention' ? ['rgba(4, 129, 248, 0.2)', 'rgba(4, 129, 248, 0.5)','rgba(4, 129, 248, 1)']
    : metric==='Interest' ? ['rgba(249, 135, 0, 0.2)','rgba(249, 135, 0, 0.5)','rgba(249, 135, 0, 1)']
    : metric==='Action' ? ['rgba(214, 48, 49, 0.2)','rgba(214, 48, 49, 0.5)','rgba(214, 48, 49, 1)']
    : metric==='Desire' ? ['rgba(8,181,55, 0.1)','rgba(8,181,55, 0.25)','rgba(8,181,55, 0.40)','rgba(8,181,55, 0.6)','rgba(8,181,55, 0.8)', 'rgba(8,181,55, 1)']
    : metric==='Satisfaction' ? ['rgba(127, 8, 247, 0.1)','rgba(127, 8, 247, 0.25)','rgba(127, 8, 247, 0.40)','rgba(127, 8, 247, 0.6)','rgba(8,181,55, 0.8)', 'rgba(8,181,55, 1)']
    : ''

  const convertedTreeData = () => {
    return {
      name:  metric,
      children: data
        .filter(d => !!d.value)
        .map(d => ({
          name: d.label,
          children: [{
            name:  d.label,
            value: d.value,
          }],
        })),
    }
  }

  return (
    <>
      <Div iff={typeof window !== 'undefined' && ResponsiveTreeMap && loadProgress===100}>
        <div className='h-[17rem] w-80 sm:w-64 lg:pl-2 lg:w-56 xl:w-64 xl:pl-8 2xl:w-72'>
          <ResponsiveTreeMap
            data={convertedTreeData()}
            identity='name'
            margin={{ top: 10, right: 5, bottom: 10, left: 5 }}
            colors={colors}
            leavesOnly={true}
            label={(node) => `${node.id} (${node.formattedValue})`}
            valueFormat=' >-,.2s'
            // label='id'
            // borderColor={{
            //   from: 'color',
            //   modifiers: [
            //     [ 'opacity', 0.3 ],
            //   ],
            // }}
            borderWidth={0}
            animate={true}
            isInteractive={true}
            tooltip={({ node }) => (
              <strong style={{ color: node.color }}>
                {node.pathComponents.join(' / ')}: {node.formattedValue}
              </strong>
            )}
          />
        </div>
      </Div>
      <Div iff={loadProgress<100}>
        <ProgressBar size='small' progress={Math.min(100,progressCount)} />
      </Div>
    </>
  )
}

export default TreeMap
