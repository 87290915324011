import { Lang } from '../enums/lang'

export const AIDAS = {
  Attention: {
    [Lang.En]: {
      letter: 'A',
      name: 'Attention',
      metric: 'Impressions',
    },
    [Lang.Ja]: {
      letter: 'A',
      name: '注目',
      metric: 'インプレッション数',
    },
  },
  Interest: {
    [Lang.En]: {
      letter: 'I',
      name: 'Interest',
      metric: 'Clicks',
    },
    [Lang.Ja]: {
      letter: 'I',
      name: '関心',
      metric: 'クリック数',
    },
  },
  Desire: {
    [Lang.En]: {
      letter: 'D',
      name: 'Desire',
      metric: 'Engagements',
    },
    [Lang.Ja]: {
      letter: 'D',
      name: '切望',
      metric: 'エンゲージメント数',
    },
  },
  Action: {
    [Lang.En]: {
      letter: 'A',
      name: 'Action',
      metric: 'Orders',
    },
    [Lang.Ja]: {
      letter: 'A',
      name: '購入',
      metric: '新規注文数',
    },
  },
  Satisfaction: {
    [Lang.En]: {
      letter: 'S',
      name: 'Satisfaction',
      metric: 'Reorders',
    },
    [Lang.Ja]: {
      letter: 'S',
      name: '再購入',
      metric: '返品注文数',
    },
  },
}