import React from 'react'
import { DisplayText, Spinner, TextStyle } from '@shopify/polaris'
import { Div } from 'components/ui'
import useLang from 'lib/hooks/useLang'
import minusArrow from '../../../../images/dashboard/red_arrow.svg'
import TrendIndicator from 'components/ui/TrendIndicator'

type MetricDataCardProps = {
  // TODO remove optional ? on loading
  loading?: boolean
  dataTitle: string
  changeValue: number
  dataNumber: string
  dataPercentage: string
  publisherLogo?: any
  positivity?: boolean
}

const MetricDataCard: React.FC<MetricDataCardProps> = ({loading=false, dataTitle, changeValue, dataNumber, dataPercentage, publisherLogo, positivity=true }) => {
  const { translate, setLang, lang } = useLang()

  const cpm = translate('Marketing.cpm')
  const cpc = translate('Marketing.cpc')
  const bounceRate = translate('Marketing.bounceRate')
  const conversionRate = translate('Marketing.conversionRate')
  const repeatToNew = translate('Marketing.repeatToNew')

  const getTitle = () => dataTitle==='CPM' ? cpm
                       : dataTitle==='CPC' ? cpc
                       : dataTitle==='Bounce Rate' ? bounceRate
                       : dataTitle==='Conversion Rate' ? conversionRate
                       : repeatToNew

  const getBgGradient = () => dataTitle === 'CPM' ? 'attentionBgGradient'
                            : dataTitle === 'CPC' ? 'interestBgGradient'
                            : dataTitle === 'Bounce Rate' ? 'satisfactionBgGradient'
                            : dataTitle === 'Conversion Rate' ? 'actionBgGradient'
                            : dataTitle === 'Repeat-Rate' ? 'satisfactionBgGradient'
                            : 'desireBgGradient'

  return (
    <>
      <Div iff={!loading} className={`justify-end pb-10 flex flex-col items-center relative rounded-xl aidasBorder2 h-full gap-2  text-center text-white ${getBgGradient()}`}
        smScreen='justify-center pb-0'
        lgScreen='px-4 justify-end pb-[5.5rem] gap-0'
        xl2Screen='pb-[4.8rem] gap-1'
      >
        <p className='text-sm w-full font-semibold lg:text-xl'>
          {getTitle()}
        </p>
        {/* lg text size  */}
        <div className='hidden lg:block xl:hidden lg:pb-2'>
          <DisplayText size='medium'>
            {dataNumber}
          </DisplayText>
        </div>
        <div className='lg:hidden xl:block pb-5 sm:pb-1 xl:pb-3'>
          <DisplayText size='large'>
            {dataNumber}
          </DisplayText>
        </div>
        <TrendIndicator
          changeValue={changeValue}
          dataPercentage={dataPercentage}
          positivity={positivity}
          isMetric={true}
          vsNotation='last week'
          isMetricAction={dataTitle === 'Conversion Rate'}
          isMetricInterest={dataTitle === 'CPC'}
          isMetricSatisfaction={dataTitle === 'Repeat-Rate'}
          isMetricDesire={dataTitle === 'Engagement Rate'} />
        <div className='absolute right-9 w-14 h-14'>
          {publisherLogo ? <img src={publisherLogo} alt='publisher logo' /> : <></>}
        </div>
      </Div>
      <Div iff={loading} className='flex justify-center items-center py-24'>
        <Spinner accessibilityLabel={`${dataTitle} data is loading...`} size='small' />
      </Div>
    </>
  )
}

export default MetricDataCard
