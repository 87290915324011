import React, { useCallback, useState } from 'react'
import {Button, ButtonGroup, DatePicker } from '@shopify/polaris'
import { useRecoilState } from 'recoil'
import * as dates from '@1f8/commons/date'

import { DateRange } from '@sh/models/interfaces'
import useLang from 'lib/hooks/useLang'

import * as atoms from 'lib/atoms'
import * as log from 'lib/method/log'

// TODO Use enums?
const options = [
  {label: 'Last 30 days', value: 'Last 30 days'},
  {label: 'This Week', value: 'This Week'},
  {label: 'Last Week', value: 'Last Week'},
  {label: 'Last Month', value: 'Last Month'},
  {label: 'Last Quarter', value: 'Last Quarter'},
  {label: 'Last Year', value: 'Last Year'},
  {label: 'This Year', value: 'This Year'},
]

const SelectDateComponent = () => {
  const [popoverActive, setPopoverActive] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useRecoilState(atoms.selectedDateRange)
  const [localDateRange, setLocalDateRange] = useState({...selectedDateRange})
  const [{month, year}, setDate] = useState({month: localDateRange.start.getMonth(), year: localDateRange.start.getFullYear()})
  const [selectedCompareDateRange, setSelectedCompareDateRange] = useRecoilState(atoms.selectedCompareDateRange)
  const [localCompareDateRange, setLocalCompareDateRange] = useState({...selectedCompareDateRange})
  const [{compareMonth, compareYear}, setCompareDate] = useState({compareMonth: localCompareDateRange.start.getMonth(), compareYear: localCompareDateRange.start.getFullYear()})
  const [selected, setSelected] = useState<string>()
  const [selectDate, setSelectDate] = useState(false)
  const [selectMobileDate, setSelectMobileDate] = useRecoilState(atoms.selectMobileDate)
  const [comparisonDate, setComparisonDate] = useRecoilState(atoms.comparisonDate)

  const { translate } = useLang()

  const last30days = translate('SelectDate.last30Days')
  const thisWeek = translate('SelectDate.thisWeek')
  const lastWeek = translate('SelectDate.lastWeek')
  const lastMonth = translate('SelectDate.lastMonth')
  const lastQuarter = translate('SelectDate.lastQuarter')
  const lastYear = translate('SelectDate.lastYear')
  const thisYear = translate('SelectDate.thisYear')
  const comparisonDateText = translate('SelectDate.comparisonDate')

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )
  const toggleSelectDate = useCallback(
    () => setSelectDate((selectDate) => !selectDate),
    [],
  )

  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  )
  const handleCompareMonthChange = useCallback(
    (compareMonth, compareYear) => setCompareDate({compareMonth, compareYear}),
    [],
  )

  const activator = (
    <div className='whitespace-nowrap bg-gray-settings px-5 py-3 rounded-xl'>
      <button type='button' onClick={togglePopoverActive} className='font-bold'>
        {selectedDateRange.start.toLocaleDateString()} - {selectedDateRange.end.toLocaleDateString()}
      </button>
    </div>
  )

  const cancelDate = () => {
    setSelectDate(false)
    setSelectMobileDate(false)
  }

  const handleDropdownDates = (value, isCompare?) => {
    // create unit-testable method (date lib)
    setSelected(value)
    const dateRange:DateRange = value === 'Last 30 days'
      ? dates.getLastThirtyDaysRange()
      : value === 'This Week'
      ? dates.getThisWeekRange()
      : value === 'Last Week'
      ? dates.getLastWeekRange()
      : value === 'Last Month'
      ? dates.getLastMonthRange()
      : value === 'Last Quarter'
      ? dates.getPrevQDate(dates.calcJapanQ())
      : value === 'Last Year'
      ? dates.getLastYearRange()
      : value === 'This Year'
      ? dates.getThisYearRange()
      : null
    if (dateRange && isCompare) {
      setCompareDate({
        compareMonth: dateRange.start.getMonth(),
        compareYear: dateRange.start.getFullYear(),
      })
      setLocalCompareDateRange(dateRange)
    }
    else if (dateRange) {
      setDate({
        month: dateRange.start.getMonth(),
        year: dateRange.start.getFullYear(),
      })
      setLocalDateRange(dateRange)
    }
  }

  const applyDateRange = () => {
    setSelectedDateRange(localDateRange)
    setSelectedCompareDateRange(localCompareDateRange)
    setSelectDate(false)
    setSelectMobileDate(false)
  }

  return (
    <>
      <div>
        <div className='whitespace-nowrap bg-gray-settings px-5 py-3 rounded-xl hidden sm:block'>
          <button type='button' onClick={toggleSelectDate} className='font-bold'>
            {selectedDateRange.start.toLocaleDateString()} - {selectedDateRange.end.toLocaleDateString()}
          </button>
        </div>
        { (selectDate || selectMobileDate) &&
          <div className='absolute w-128 py-8 bg-white right-2 sm:right-36 md:right-128 xl:right-36 sm:top-28 sm:max-h-[80vh] sm:overflow-auto z-20 border border-gray-settings rounded-xl shadow-lg px-6 lg:w-[65%] xl:w-[49rem] h-[70vh] overflow-y-scroll lg:h-auto'>
            <div className='lg:flex flex-row-reverse items-center'>
              <div className='flex flex-wrap gap-3 text-base lg:text-xl pb-8 lg:flex-col lg:pl-10 lg:pr-2 whitespace-nowrap lg:justify-center'>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last 30 days')}>{last30days}</button>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('This Week')}>{thisWeek}</button>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Week')}>{lastWeek}</button>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Month')}>{lastMonth}</button>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Quarter')}>{lastQuarter}</button>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Year')}>{lastYear}</button>
                <button type='button' className='dateTag' onClick={() => handleDropdownDates('This Year')}>{thisYear}</button>
              </div>
              <div className='px-3 py-6 aidasBorder2'>
                <DatePicker
                  month={month}
                  year={year}
                  onMonthChange={handleMonthChange}
                  onChange={setLocalDateRange}
                  selected={localDateRange}
                  allowRange
                />
              </div>
            </div>
            {comparisonDate &&
              <>
                <div className='centerBorder whitespace-nowrap py-6 font-bold'>
                  {comparisonDateText}
                </div>
                <div className='lg:flex flex-row-reverse items-center'>
                  <div className='flex flex-wrap gap-3 text-base lg:text-lg pb-8 lg:flex-col lg:pl-10 lg:pr-2 whitespace-nowrap lg:justify-center'>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last 30 days', true)}>{last30days}</button>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('This Week', true)}>{thisWeek}</button>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Week', true)}>{lastWeek}</button>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Month', true)}>{lastMonth}</button>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Quarter', true)}>{lastQuarter}</button>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('Last Year', true)}>{lastYear}</button>
                    <button type='button' className='dateTag' onClick={() => handleDropdownDates('This Year', true)}>{thisYear}</button>
                  </div>
                  <div className='px-3 py-6 aidasBorder2'>
                    <DatePicker
                      month={compareMonth}
                      year={compareYear}
                      onMonthChange={handleCompareMonthChange}
                      onChange={setLocalCompareDateRange}
                      selected={localCompareDateRange}
                      allowRange
                    />
                  </div>
                </div>
              </>}
            <div className='flex gap-4 pt-8 lg:pt-6 items-center justify-end'>
              <p className='font-semibold whitespace-nowrap'>{comparisonDateText}</p>
              <input type='checkbox' id='check1' name='comparison date' checked={comparisonDate} onChange={() => setComparisonDate(comparisonDate => !comparisonDate)} />
              <label htmlFor='check1'>
              </label>
            </div>
            <div className='pt-7 border-t border-gray-center-border mt-6 flex justify-end items-center'>
              <ButtonGroup>
                <Button onClick={cancelDate}>Cancel</Button>
                <Button primary onClick={applyDateRange}>Apply</Button>
              </ButtonGroup>
            </div>
          </div>}
      </div>
    </>
  )
}

export default SelectDateComponent
