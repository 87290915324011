import React, { useMemo } from 'react'
import { DisplayText, Spinner, TextStyle } from '@shopify/polaris'
import { Div } from 'components/ui'
import downRedArrow from 'images/dashboard/red_arrow.svg'
import upGreenArrow from 'images/dashboard/upGreenArrow.svg'
import downGreenArrow from 'images/dashboard/downGreenArrow.svg'
import upRedArrow from 'images/dashboard/upRedArrow.svg'
import downMetricRedArrow from 'images/dashboard/downMetricRedArrow.svg'
import upMetricGreenArrow from 'images/dashboard/upMetricGreenArrow.svg'
import downMetricGreenArrow from 'images/dashboard/downMetricGreenArrow.svg'
import upMetricRedArrow from 'images/dashboard/upMetricRedArrow.svg'
import upMetricWhiteArrow from 'images/dashboard/upMetricWhiteArrow.svg'
import downMetricWhiteArrow from 'images/dashboard/downMetricWhiteArrow.svg'
/**
 * vsNotation ex)
 * - last Week
 * - last Month
 * etc...
*/
type TrendIndicatorProps = {
  changeValue: number
  dataPercentage: string
  positivity: boolean
  vsNotation: string
  isMetric?: boolean
  isMetricAction?: boolean
  isMetricDesire?: boolean
  isMetricInterest?: boolean
  isMetricSatisfaction?: boolean
}

const TrendIndicator: React.FC<TrendIndicatorProps> = ({
  changeValue,
  dataPercentage,
  vsNotation,
  positivity=true,
  isMetric=false,
  isMetricAction=false,
  isMetricDesire=false,
  isMetricInterest=false,
  isMetricSatisfaction=false,
}) => {
  const gVariation = () => positivity && changeValue >= 0 ? 'text-green-desire'
    : positivity ? 'text-red-action'
    : changeValue >= 0 ? 'text-red-action'
    : 'text-green-desire'

  const renderArrow = () => {
    if (positivity && changeValue > 0) {
      return upGreenArrow
    }
    if (positivity && changeValue < 0) {
      return downRedArrow
    }
    if (!positivity && changeValue < 0) {
      return downGreenArrow
    }
    if (!positivity && changeValue > 0) {
      return upRedArrow
    }
    return ''
  }

  const renderMetricArrow = () => {
    if (positivity && changeValue > 0) {
      return upMetricWhiteArrow
    }
    if (positivity && changeValue < 0) {
      return downMetricWhiteArrow
    }
    if (!positivity && changeValue < 0) {
      return downMetricWhiteArrow
    }
    if (!positivity && changeValue > 0) {
      return upMetricWhiteArrow
    }
    return ''
  }

  return (
    <>
      <div className={isMetric ? 'flex gap-2 pt-1 flex-col lg:gap-4' : 'flex gap-2 pt-1 flex-col'}>
        <div className={isMetric ? 'flex flex-col lg:-mt-1' : 'flex items-center lg:mx-0 gap-2 text-left'}>
          <div className='flex items-center justify-center gap-1'>
            <Div iff={renderArrow()}>
              <img src={isMetric ? renderMetricArrow() : renderArrow()} alt='indicate the numbers' className='w-5 h-5' />
            </Div>
            <p className={`${isMetric ? 'text-white' : gVariation()} ${isMetric && 'font-extrabold'}`}>
              {dataPercentage}
            </p>
          </div>
          <span className={isMetric ? 'ml-2 text-sm text-white font-semibold whitespace-nowrap 2xl:text-lg' : 'ml-2 text-sm text-black-vsLastPeriod whitespace-nowrap 2xl:text-lg'}>
            vs {vsNotation}
          </span>
        </div>
      </div>
    </>
  )
}

export default TrendIndicator
