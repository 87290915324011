import React, { useState } from 'react'
import DropdownMenu from 'components/ui/DropdownMenu'

type SettingsNavProps = {
}

const SettingsNavComponent = () => {
  return (
    <>
      <div className='px-4 sm:px-12 md:px-16 pb-10 -mt-6 sm:py-8 sm:mt-0 2xl:max-w-1020px 2xl:mx-auto'>
        <DropdownMenu menu='Settings' />
      </div>
      <div className='hidden sm:block border-b border-gray-100 w-full'></div>
    </>
  )
}

export default SettingsNavComponent
