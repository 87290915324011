import React, { useEffect } from 'react'
import { FaBusinessTime, FaHandshake } from 'react-icons/fa'
import { GiClick, GiWorld } from 'react-icons/gi'
import { HiOutlineShoppingCart } from 'react-icons/hi'

import { Div } from 'components/ui'
import useLang from 'lib/hooks/useLang'
import MetricHeading from './dashboard/Metric/MetricHeading'
import MetricSummaryComponent from './dashboard/Metric/MetricSummary'
import PublisherCampaignSummaryComponent from './dashboard/PublisherCampaignSummary'
import TopCardGroup from './dashboard/Top/TopCardGroup'
import DashboardNav from './dashboard/DashboardNav'

const DashboardComponent = ({demoMode=false}) => {
  const { translate, setLang, lang } = useLang()

  useEffect(() => {
  }, [])

  const attentionCardDividerMsg = translate('Demo.attentionDivider')
  const interestDividerMsg = translate('Demo.interestDivider')
  const desireDividerMsg = translate('Demo.desireDivider')
  const actionDividerMsg = translate('Demo.actionDivider')
  const satisfactionDividerMsg = translate('Demo.satisfactionDivider')

  return (
    <Div className='bg-gray-settings h-full pb-10'>
      <DashboardNav />
      <div className='lg:pt-20'>
        <TopCardGroup />
        <div className='bg-white mb-16 pb-10 sm:px-8 md:px-24 lg:px-0 xl:px-10 rounded-2xl lg:mx-auto lg:max-w-[98%] xl:max-w-[990px] 2xl:max-w-[1089px]'>
          {/* A */}
          <MetricHeading metric='Attention' text={attentionCardDividerMsg}><GiWorld /></MetricHeading>
          <MetricSummaryComponent metric='Attention' />
          <PublisherCampaignSummaryComponent metric='Attention' />
          {/* I */}
          <MetricHeading metric='Interest' text={interestDividerMsg}><GiClick /></MetricHeading>
          <MetricSummaryComponent metric='Interest' />
          <PublisherCampaignSummaryComponent metric='Interest' />
          {/* D */}
          <MetricHeading metric='Desire' text={desireDividerMsg}><FaBusinessTime /></MetricHeading>
          <MetricSummaryComponent metric='Desire' />
          <PublisherCampaignSummaryComponent metric='Desire' />
          {/* A */}
          <MetricHeading metric='Action' text={actionDividerMsg}><HiOutlineShoppingCart /></MetricHeading>
          <MetricSummaryComponent metric='Action' />
          <PublisherCampaignSummaryComponent metric='Action' />
          {/* S */}
          <MetricHeading metric='Satisfaction' text={satisfactionDividerMsg}><FaHandshake /></MetricHeading>
          <MetricSummaryComponent metric='Satisfaction' />
          <PublisherCampaignSummaryComponent metric='Satisfaction' />
        </div>
      </div>
    </Div>
  )
}

export default DashboardComponent
