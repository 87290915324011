import React from 'react'
import TopCard from './TopCard'
import DataCard from '../DataCard'
import TreeMap from '../Charts/TreeMap'
import useLang from 'lib/hooks/useLang'
import useDashboardData from 'lib/hooks/useDashboardData'
import gaIcon from 'images/dashboard/grayGA.svg'
import { formatPercent, numberWithCommas } from 'lib/method/formatNumber'
import { Spinner, Tooltip } from '@shopify/polaris'
import { Div } from 'components/ui'


const TopActionGroup = () => {
  const { translate } = useLang()
  const conversionsTitle = translate('Marketing.conversions')
  const conversionRate = translate('Marketing.conversionRate')
  const conversionNumber = translate('Marketing.conversionNumber')
  const sessions = translate('Marketing.sessions')

  const {
    gaLoadingProgress,
    gaChannelsProgress,
    actionData,
    calcConversionBreakdown,
    calcConversionChangeBreakdown,
    gaTotal,
  } = useDashboardData()

  const getContent = (data:any) => {
    const def = `${conversionRate} = ${conversionNumber} / ${sessions}`
    const desc = `${numberWithCommas(data.rateNum)} / ${numberWithCommas(data.rateDen)} = ${formatPercent(data.rate*100)}`
    const chDesc = `${numberWithCommas(data.last.rateNum)} / ${numberWithCommas(data.last.rateDen)} = ${formatPercent(data.last.rate*100)}`
    return (<div>
      <p><b>{def}</b></p>
      <hr className='mt-2' />
      <p><b>This Period:</b> {desc}</p>
      <hr />
      <p><b>Last Period:</b> {chDesc}</p>
    </div>)
  }
  // const getContent = () => {
  //   return `${conversionRate} => ${conversionNumber} / ${sessions} * 100 => ${gActionTotal()} / ${(gaTotal==null) ? 0 : gaTotal.sessions} * 100 = ${formatPercent(gActionRate())}`
  // }

  return (
    <div className='cardTop'>
      <div className='topCardStyle rounded-t-2xl pb-3'>
        <TopCard
          loadProgress={gaLoadingProgress()}
          metric='Action'
          value={actionData.value}
          changeValue={actionData.changePercent.value}
          dataPercentage={formatPercent(actionData.changePercent.value*100)}
        />
      </div>
      <div className='lg:w-full 2xl:w-auto'>
        <Tooltip content={getContent(actionData)}>
          <DataCard
            dataTitle={conversionRate}
            changeValue={actionData.change.rate}
            positivity={true}
            dataNumber={formatPercent(actionData.rate*100, 2)}
            dataPercentage={formatPercent(actionData.change.rate*100)}
          />
        </Tooltip>
      </div>
      <div className={gaChannelsProgress() ? 'topCardStyle pt-7': 'topCardStyle pt-7 pb-4'}>
        <TreeMap data={calcConversionBreakdown()} metric='Action' />
      </div>
      { gaChannelsProgress()
        ? (
          <Div className='flex justify-center items-center py-24'>
            <Spinner accessibilityLabel='google channel data is loading...' size='small' />
          </Div>
        )
        : calcConversionChangeBreakdown().map(ch => (
          <DataCard
            key={ch.id}
            loading={gaChannelsProgress()}
            dataTitle={ch.id}
            changeValue={ch.change}
            dataNumber={formatPercent(ch.value, 2)}
            dataPercentage={formatPercent(ch.change)}
            publisherLogo={gaIcon}
          />
        ))}
    </div>
  )
}



export default TopActionGroup
