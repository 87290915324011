import React from 'react'
import { DisplayText, TextStyle } from '@shopify/polaris'
import { Integration } from '@sh/enums'
import { StaticImage } from 'gatsby-plugin-image'

type PublisherCardProps = {
  integration: Integration
  name: string
  value: string
  percentChange: number
}

const PublisherCardComponent = ({ integration, name, value, percentChange }: PublisherCardProps) => {

  const formatPercentChange = () => {
    if (Number.isFinite(percentChange)) {
      return `${percentChange>0?'+':''}${ Math.floor(percentChange) }%`
    }
    return '-'
  }

  // const getIconColor = (integration:Integration) => {
  //   return integration===Integration.FACEBOOK ? 'text-blue-fb'
  //   : integration===Integration.GOOGLE   ? 'text-red-yahoo'
  //   : integration===Integration.YJP      ? 'text-red-yahoo'
  //   : null
  // }

  return (
    <div className='flex flex-col px-4 lg:px-3 pt-4 pb-2 items-start aidasBorder3 sm:px-8 xl:px-5 sm:py-4 xl:py-6 w-[49%] lg:w-[24.5%] xl:w-[19.5%] justify-start'>
      <div className='w-full flex flex-row min-h-[40px] mb-2 lg:mb-4 items-start justify-between'>
        <p className='text-base font-semibold sm:text-lg'>
          {/* <span className={getIconColor(integration)}> */}
          {name}
        </p>
        <div className='2xl:pl-2'>
          {integration === Integration.FACEBOOK
            ? <StaticImage src='../../../images/dashboard/grayFb.svg'
              alt='Publisher: Facebook!'
              placeholder='blurred'
              width={35}
              height={35}
              className='w-10 sm:w-12 lg:w-12'
            />
            : integration === Integration.GOOGLE
            ? <StaticImage src='../../../images/dashboard/grayGoogle.svg'
              alt='Publisher: Google'
              placeholder='blurred'
              width={35}
              height={35}
              className='w-10 sm:w-12 lg:w-12'
            />
            : integration === Integration.YJP
            ? <StaticImage src='../../../images/dashboard/grayYahoo.svg'
              alt='Publisher: Yahoo Japan'
              placeholder='blurred'
              width={35}
              height={21}
              className='w-10 sm:w-14 lg:w-12'
            />
            : integration === Integration.GA
            ? <StaticImage src='../../../images/dashboard/grayGA.svg'
              alt='Google Analytics'
              placeholder='blurred'
              width={31}
              height={35}
              className='w-7 sm:w-7 lg:w-8'
            />
            : integration === Integration.SHOPIFY
            ? <StaticImage src='../../../images/dashboard/grayShopify.svg'
              alt='Shopify logo'
              placeholder='blurred'
              width={35}
              height={35}
              className='w-12 -mt-1 sm:w-12 lg:w-14'
            />
            : null}
        </div>
      </div>
      {/* lg text size  */}
      <div className='hidden lg:block xl:hidden'>
        <DisplayText size='medium'>
          {value}
        </DisplayText>
      </div>
      <div className='lg:hidden xl:block'>
        <DisplayText size='large'>
          {value}
        </DisplayText>
      </div>
      {percentChange && (<>
        <div className='pt-2 flex items-center'>
          <p className={percentChange > 0 ? 'text-green-desire' : 'text-red-action'}>
            {formatPercentChange()}
          </p>
          <span className='ml-2 text-sm font-normal text-black-vsLastPeriod '>
            vs Last Period
          </span>
        </div>
      </>)}
      {/* <SparkLineChart /> */}
    </div>
  )
}

export default PublisherCardComponent
