import React, { useCallback, useState } from 'react'
import { useRecoilState } from 'recoil'
import { ActionList, Popover, Select } from '@shopify/polaris'
import { Icon } from '@shopify/polaris'
import { SettingsMajor } from '@shopify/polaris-icons'

import * as atoms from 'lib/atoms'
import { Lang } from '@sh/enums'
import { Screen } from 'lib/enum'
import useLang from 'lib/hooks/useLang'
import { StaticImage } from 'gatsby-plugin-image'
import { Div } from 'components/ui'

const DashboardSettingsComponent = () => {
  const [selectSettings, setSelectSettings] = useRecoilState(atoms.selectSettings)
  const [comparisonDate, setComparisonDate] = useRecoilState(atoms.comparisonDate)
  const [campaign, setCampaign] = useState(false)

  const { translate } = useLang()

  const comparisonDateText = translate('SettingNav.comparisonDate')
  const campaignText = translate('SettingNav.campaign')
  const filterCampaign = translate('SettingNav.filterCampaign')
  const filterAdName = translate('SettingNav.filterAdName')
  const clear = translate('SettingNav.clear')
  const allCampaigns = translate('SettingNav.allCampaigns')
  const prospectings = translate('SettingNav.prospectings')
  const otherPaidSocial = translate('SettingNav.otherPaidSocial')
  const remarking = translate('SettingNav.remarking')
  const allAds = translate('SettingNav.allAds')
  const imageAds = translate('SettingNav.imageAds')
  const videoAds = translate('SettingNav.videoAds')
  const clickAttribution = translate('SettingNav.clickAttribution')
  const viewAttribution = translate('SettingNav.viewAttribution')
  const clickType = translate('SettingNav.clickType')
  const unique = translate('SettingNav.unique')
  const outbound = translate('SettingNav.outbound')
  const uniqueOutbound = translate('SettingNav.uniqueOutbound')
  const defaultText = translate('SettingNav.default')

  // All Campaigns
  const [allCampaignActive, setAllCampaignActive] = useState(false)

  const toggleAllCampaignActive = useCallback(
    () => setAllCampaignActive((allCampaignActive) => !allCampaignActive),
    [],
  )

  const allCampaignActivator = (
    <button type='button' onClick={toggleAllCampaignActive}
      className='flex items-center gap-6'
    >
      <p className='font-semibold'>{allCampaigns}</p>
      {allCampaignActive ?
        <StaticImage src='../../../images/dashboard/upArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        /> :
        <StaticImage src='../../../images/dashboard/downArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        />}
    </button>
  )

  const allCampaignsItems = [
    { content: allCampaigns },
    { content: prospectings },
    { content: otherPaidSocial },
    { content: remarking },
  ]

  // All Ads
  const [allAdsActive, setAllAdsActive] = useState(false)

  const toggleAllAdsActive = useCallback(
    () => setAllAdsActive((allAdsActive) => !allAdsActive),
    [],
  )

  const allAdsActivator = (
    <button type='button' onClick={toggleAllAdsActive}
      className='flex items-center gap-6'
    >
      <p className='font-semibold'>{allAds}</p>
      {allAdsActive ?
        <StaticImage src='../../../images/dashboard/upArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        /> :
        <StaticImage src='../../../images/dashboard/downArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        />}
    </button>
  )

  const allAdsItems = [
    { content: allAds },
    { content: imageAds },
    { content: videoAds },
  ]

  // Click Attribution
  const [clickAttActive, setClickAttActive] = useState(false)

  const toggleClickAttActive = useCallback(
    () => setClickAttActive((clickAttActive) => !clickAttActive),
    [],
  )

  const clickAttActivator = (
    <button type='button' onClick={toggleClickAttActive}
      className='flex items-center gap-6'
    >
      <p className='font-semibold'>{defaultText}</p>
      {clickAttActive ?
        <StaticImage src='../../../images/dashboard/upArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        /> :
        <StaticImage src='../../../images/dashboard/downArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        />}
    </button>
  )

  const clickAttItems = [
    { content: 'Off' },
    { content: defaultText },
    { content: '1' },
    { content: '7' },
  ]

  // View Attribution
  const [viewAttActive, setViewAttActive] = useState(false)

  const toggleViewAttActive = useCallback(
    () => setViewAttActive((viewAttActive) => !viewAttActive),
    [],
  )

  const viewAttActivator = (
    <button type='button' onClick={toggleViewAttActive}
      className='flex items-center gap-6'
    >
      <p className='font-semibold'>{defaultText}</p>
      {viewAttActive ?
        <StaticImage src='../../../images/dashboard/upArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        /> :
        <StaticImage src='../../../images/dashboard/downArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        />}
    </button>
  )

  const viewAttItems = [
    { content: 'Off' },
    { content: defaultText },
    { content: '1' },
  ]

  // Click Type
  const [clickTypeActive, setClickTypeActive] = useState(false)

  const toggleClickTypeActive = useCallback(
    () => setClickTypeActive((clickTypeActive) => !clickTypeActive),
    [],
  )

  const clickTypeActivator = (
    <button type='button' onClick={toggleClickTypeActive}
      className='flex items-center gap-6'
    >
      <p className='font-semibold'>{defaultText}</p>
      {clickTypeActive ?
        <StaticImage src='../../../images/dashboard/upArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        /> :
        <StaticImage src='../../../images/dashboard/downArrow.svg'
          alt='close menu'
          placeholder='blurred'
          width={12}
          height={7}
        />}
    </button>
  )

  const clickTypeItems = [
    { content: defaultText },
    { content: unique },
    { content: outbound },
    { content: uniqueOutbound },
  ]

  return (
    <>
      {selectSettings ?
        <>
          <button type='button'
            onClick={() => setSelectSettings(b => !b)}
          >
            <StaticImage src='../../../images/dashboard/cross.svg'
            alt='close menu'
            placeholder='blurred'
            width={20}
            height={20}
          />
          </button>
          <div className='z-0 w-full h-full fixed top-0 bottom-0 right-0 left-0' onClick={() => setSelectSettings(prev => !prev)}></div>
          <Div className='absolute w-96 py-8 bg-white right-4 top-28 sm:right-16 md:right-[25rem] xl:right-16 z-20 border border-gray-settings rounded-xl shadow-lg px-8'>
            <div className='pt-2'>
              <details>
                <summary>
                  <div onClick={() => setCampaign(campaign => !campaign)}
                    className='flex gap-6 items-center'>
                    <p className='font-semibold'>{campaignText}</p>
                    {campaign ?
                      <StaticImage src='../../../images/dashboard/upArrow.svg'
                      alt='close menu'
                      placeholder='blurred'
                      width={12}
                      height={7}
                    /> :
                      <StaticImage src='../../../images/dashboard/downArrow.svg'
                      alt='close menu'
                      placeholder='blurred'
                      width={12}
                      height={7}
                    />}
                  </div>
                </summary>
                <p className='text-sm pt-4 pl-1'>{filterCampaign}</p>
                <input type='text' className='rounded-lg border-2 border-gray-settings py-1 mt-2' />
                <p className='text-sm pt-4 pl-1'>{filterAdName}</p>
                <input type='text' className='rounded-lg border-2 border-gray-settings py-1 mt-2' />
                <button type='button' className='pl-1 text-red-notConnected font-semibold pt-3'>{clear}</button>
              </details>
            </div>
            <div className='pt-7'>
              <Popover
                active={allCampaignActive}
                activator={allCampaignActivator}
                autofocusTarget='first-node'
                onClose={toggleAllCampaignActive}
              >
                <ActionList
                  actionRole='menuitem'
                  items={allCampaignsItems}
                />
              </Popover>
            </div>
            <div className='pt-7'>
              <Popover
                active={allAdsActive}
                activator={allAdsActivator}
                autofocusTarget='first-node'
                onClose={toggleAllAdsActive}
              >
                <ActionList
                  actionRole='menuitem'
                  items={allAdsItems}
                />
              </Popover>
            </div>
            <div className='pt-7'>
              <p className='text-base'>{clickAttribution}</p>
              <Popover
                active={clickAttActive}
                activator={clickAttActivator}
                autofocusTarget='first-node'
                onClose={toggleClickAttActive}
              >
                <ActionList
                  actionRole='menuitem'
                  items={clickAttItems}
                />
              </Popover>
            </div>
            <div className='pt-7'>
              <p className='text-base'>{viewAttribution}</p>
              <Popover
                active={viewAttActive}
                activator={viewAttActivator}
                autofocusTarget='first-node'
                onClose={toggleViewAttActive}
              >
                <ActionList
                  actionRole='menuitem'
                  items={viewAttItems}
                />
              </Popover>
            </div>
            <div className='pt-7'>
              <p className='text-base'>{clickType}</p>
              <Popover
                active={clickTypeActive}
                activator={clickTypeActivator}
                autofocusTarget='first-node'
                onClose={toggleClickTypeActive}
              >
                <ActionList
                  actionRole='menuitem'
                  items={clickTypeItems}
                />
              </Popover>
            </div>
          </Div>
        </>
    : <div className='pt-2'><Icon source={SettingsMajor} /></div>}
    </>
  )
}

export default DashboardSettingsComponent
