import React from 'react'
import * as atoms from 'lib/atoms'
import { useRecoilValue } from 'recoil'
import { LoadingStatus } from 'lib/enum'

import TopCard from './TopCard'
import DataCard from '../DataCard'
import TreeMap from '../Charts/TreeMap'
import useLang from 'lib/hooks/useLang'
import useDashboardData from 'lib/hooks/useDashboardData'
import { fmtCurrency, formatPercent, numberWithCommas } from 'lib/method/formatNumber'

import fbIcon from 'images/dashboard/grayFb.svg'
import googleIcon from 'images/dashboard/grayGoogle.svg'
import yahooIcon from 'images/dashboard/grayYahoo.svg'
import { Tooltip } from '@shopify/polaris'
import { Div } from 'components/ui'


const TopAttentionGroup = () => {
  const loadingData = useRecoilValue(atoms.loadingData)
  const { translate, lang } = useLang()
  const cpm = translate('Marketing.cpm')
  const spend = translate('Marketing.spend')
  const impressions = translate('Marketing.impressions')
  const {
    publisherLoadingProgress,
    attentionData,
    attentionFBData,
    attentionGData,
    attentionYJPData,
    calcImpressionBreakdown,
  } = useDashboardData()

  const getContent = (data:any) => {
    const def = `${cpm} = ${spend} / (${impressions}/1000)`
    const desc = `${numberWithCommas(data.rateNum)} / (${numberWithCommas(data.rateDen)}/1000) = ${fmtCurrency(data.rate)}`
    const chDesc = `${numberWithCommas(data.last.rateNum)} / (${numberWithCommas(data.last.rateDen)}/1000) = ${fmtCurrency(data.last.rate)}`
    return (<div>
      <p><b>{def}</b></p>
      <hr className='mt-2' />
      <p><b>This Period:</b> {desc}</p>
      <hr />
      <p><b>Last Period:</b> {chDesc}</p>
    </div>)
  }

  return (
    <>
      <div className='cardTop'>
        <div className='topCardStyle rounded-t-2xl pb-3'>
          <TopCard
            loadProgress={publisherLoadingProgress()}
            metric='Attention'
            value={attentionData.value}
            changeValue={attentionData.changePercent.value}
            dataPercentage={formatPercent(attentionData.changePercent.value*100)}
          />
        </div>
        <div className='lg:w-full 2xl:w-auto'>
          <DataCard
            dataTitle={cpm}
            changeValue={attentionData.changePercent.rate}
            positivity={false}
            dataNumber={fmtCurrency(attentionData.rate)}
            dataPercentage={formatPercent(attentionData.changePercent.rate * 100)}
            toolTip={getContent(attentionData)}
          />
        </div>
        <div className='topCardStyle pt-7 pb-4'>
          <TreeMap data={calcImpressionBreakdown()} metric='Attention' />
        </div>
        <Div iff={!!attentionFBData?.value} className='lg:w-full 2xl:w-auto'>
          <DataCard
            loading={loadingData.facebook.total !== LoadingStatus.Completed}
            dataTitle='Facebook'
            changeValue={attentionFBData.changePercent.rate}
            dataNumber={fmtCurrency(attentionFBData.rate)}
            dataPercentage={formatPercent(attentionFBData.changePercent.rate*100)}
            publisherLogo={fbIcon}
            positivity={false}
            toolTip={getContent(attentionFBData)}
          />
        </Div>
        <Div iff={!!attentionGData?.value} className='lg:w-full 2xl:w-auto'>
          <DataCard
            loading={loadingData.google.total !== LoadingStatus.Completed}
            dataTitle='Google Ads'
            changeValue={attentionGData.changePercent.rate}
            dataNumber={fmtCurrency(attentionGData.rate)}
            dataPercentage={formatPercent(attentionGData.changePercent.rate*100)}
            publisherLogo={googleIcon}
            positivity={false}
            toolTip={getContent(attentionGData)}
          />
        </Div>
        <Div iff={!!attentionYJPData?.value} className='lg:w-full 2xl:w-auto'>
          <DataCard
            loading={loadingData.yjp.total !== LoadingStatus.Completed}
            dataTitle='Yahoo Japan'
            changeValue={attentionYJPData.changePercent.rate}
            dataNumber={fmtCurrency(attentionYJPData.rate)}
            dataPercentage={formatPercent(attentionYJPData.changePercent.rate*100)}
            publisherLogo={yahooIcon}
            positivity={false}
            toolTip={getContent(attentionYJPData)}
          />
        </Div>
      </div>
    </>
  )
}

export default TopAttentionGroup
