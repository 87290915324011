import { Integration } from '@sh/enums'

// Keys need to match with service names
const serviceNames = {
  [Integration.GOOGLE]: {
    'EN': 'Google Ads',
    'JA': 'グーグル広告',
  },
  [Integration.GA]: {
    'EN': 'Google Analytics',
    'JA': 'グーグル分析',
  },
  [Integration.YJP]: {
    'EN':  'Yahoo Japan',
    'JA': ' ヤフージャパン',
  },
  [Integration.FACEBOOK]: {
    'EN': 'Facebook',
    'JA': 'フェースブック',
  },
}

export {
  serviceNames,
}