import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useLang from 'lib/hooks/useLang'
import { Integration } from '@sh/enums'
import { Store } from '@sh/models/classes'
import gNormalBtn from 'images/google/x2web/btn_google_signin_light_normal_web@2x.png'
import gPressedBtn from 'images/google/x2web/btn_google_signin_light_pressed_web@2x.png'
import { oauthURLs } from 'lib/const/oauthURLs'
import ServiceBtnWrapper from './ServiceBtnWrapper'

type IntegrationButtonProps = {
  store: Store,
  integration: Integration,
}

const IntegrationButton = ({ store, integration }:IntegrationButtonProps) => {
  const { translate:tr } = useLang()

  const renderButton = () => {
    switch (integration) {
      case Integration.FACEBOOK:
        return (<>
          <ServiceBtnWrapper url={`${oauthURLs.fb}&state=${store?.id}`}
            pressedBtn={
              <div className='flex items-center px-5 py-3 gap-4 border-blue-fb rounded-xl border bg-gray-200'>
                <StaticImage src='../../../images/settings/fbLogin.png'
                  alt='Continue with Facebook'
                  placeholder='blurred'
                  width={28}
                  height={28} />
                <p className='text-blue-fb font-bold px-1'>
                  {tr('Settings.continueFB')}
                </p>
              </div>
            }
            normalBtn={
              <div className='flex items-center px-5 py-3 gap-4 border-blue-fb rounded-xl border'>
                <StaticImage src='../../../images/settings/fbLogin.png'
                  alt='Continue with Facebook'
                  placeholder='blurred'
                  width={28}
                  height={28} />
                <p className='text-blue-fb font-bold px-1'>
                  {tr('Settings.continueFB')}
                </p>
              </div>
            } />
        </>)
      case Integration.GOOGLE:
        return (<>
          <ServiceBtnWrapper url={`${oauthURLs.google}&state=${store?.id}`}
            pressedBtn={<img src={gPressedBtn}
            alt='Continue with Google Ads'
            placeholder='blurred'
            width={240}
            height={58} />}
            normalBtn={<img src={gNormalBtn}
            alt='Continue with Google Ads'
            placeholder='blurred'
            width={240}
            height={50} />}
          />
        </>)
      case Integration.GA:
        return (<>
          <ServiceBtnWrapper url={`${oauthURLs.ga}&state=${store?.id}`}
            pressedBtn={<div className='flex rounded-2xl border items-center justify-center h-20 pr-2 bg-gray-200'>
              <div className='px-4'>
                <StaticImage src='../../../images/settings/gaLogo.png'
                alt='Sign in with Google Analytics'
                placeholder='blurred'
                width={45}
                height={45} />
              </div>
              <p className='text-gray-google pr-2 whitespace-nowrap font-semibold text-base sm:text-2xl'>
                {tr('Settings.continueGA')}
              </p>
            </div>}
            normalBtn={<div className='flex rounded-2xl border items-center justify-center h-20 pr-2'>
              <div className='px-4'>
                <StaticImage src='../../../images/settings/gaLogo.png'
                alt='Sign in with Google Analytics'
                placeholder='blurred'
                width={45}
                height={45} />
              </div>
              <p className='text-gray-google pr-2 whitespace-nowrap font-semibold text-base sm:text-2xl'>
                {tr('Settings.continueGA')}
              </p>
            </div>}
          />
        </>)
      case Integration.YJP:
        return (<>
          <ServiceBtnWrapper url={`${oauthURLs.yjp}&state=${store?.id}`}
            pressedBtn={<div className='flex border-red-yahoo rounded-lg border-2 items-center justify-center h-16 w-80 bg-gray-200'>
              <div className='w-32 px-4'>
                <StaticImage src='../../../images/settings/yahooLogin.png'
                alt='Continue with Yahoo Japan'
                placeholder='blurred'
                width={100}
                height={55}
              />
              </div>
              <div className='flex justify-center items-center bg-red-yahoo w-full text-gray-200 font-bold h-[inherit] font-body px-8'>
                {tr('Settings.login')}
              </div>
            </div>}
            normalBtn={<div className='flex border-red-yahoo rounded-lg border-2 items-center justify-center h-16 w-80'>
              <div className='w-32 px-4'>
                <StaticImage src='../../../images/settings/yahooLogin.png'
                alt='Continue with Yahoo Japan'
                placeholder='blurred'
                width={100}
                height={55}
              />
              </div>
              <div className='flex justify-center items-center bg-red-yahoo w-full text-white font-bold h-[inherit] font-body px-8'>
                {tr('Settings.login')}
              </div>
            </div>}
          />
        </>)
      default:
    }
  }

  return (<>
    {renderButton()}
  </>)
}

export default IntegrationButton
