import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Toast } from '@shopify/polaris'
import * as atoms from 'lib/atoms'
import * as api from 'lib/apis/graphql'
import useLang from 'lib/hooks/useLang'
import ConnectIntegration from './settings/ConnectIntegration'
import AddIntegration from './settings/AddIntegration'
import IntegrationButton from './settings/IntegrationButton'
import SettingsNav from 'components/app/settings/SettingsNav'
import { Div } from 'components/ui'
import { Integration } from '@sh/enums'
import { LoadingStatus } from 'lib/enum'
import { DataProvider, DataProviderItem } from '@sh/models/classes/DataProvider'
import { BannerOption, PopupBase } from 'lib/atoms/app'
import { BannerCustom } from 'components/ui'
import { SavedAuthResponse } from '@sh/models/types/SavedAuth'
import { AdAccount } from '@sh/models/interfaces'
import { max } from '@sh/lib/calc'


const SettingsScreen = ({ demoMode=false }) => {
  const [connections, setConnections] = useState<DataProvider[]>([])
  const [savedAuths, setSavedAuths] = useState<SavedAuthResponse[]>([])

  const { translate:tr } = useLang()
  const app = useRecoilValue(atoms.clientAppSelector)
  const store = useRecoilValue(atoms.store)
  const [bannerActive, setBannerActive] = useRecoilState<BannerOption>(atoms.bannerOpt)
  const [toast, setToast] = useRecoilState<PopupBase>(atoms.toastActive)

  // STATE MANAGEMENT

  /** Initialize State / On Load */
  useEffect(() => {
    setConnections(store.integrations)
    handleGetAccounts()
  }, [demoMode, store])

  // METHODS

  const handleGetAccounts = () => {
    if (demoMode) {
      updateIntegrationAccts(DEMO_AUTHS)
    } else {
      api.store.getSavedAuths(app)
        .then(auths => {
          updateIntegrationAccts(auths)
        })
    }
  }

  const updateIntegrationAccts = (auths:SavedAuthResponse[]) => {
    console.log('update auths?', auths)
    if (!auths?.length) return
    setSavedAuths(auths)
    setConnections(prev => prev.map((dp, i) => {
      const newDP = new DataProvider(dp.getItem())
      newDP.index = i
      newDP.setAuth(auths)
      return newDP
    }))
  }

  const onAddIntegration = (integration:Integration) => {
    const newDP = new DataProvider({
      account: '',
      service: integration,
      username: '',
      accountID: '',
    })
    newDP.index = max(connections.map(c => c.index)) + 1

    if (savedAuths?.length) newDP.setAuth(savedAuths)
    console.log('adding new integratoin', { savedAuths, newDP })
    setConnections(prev => {
      return [
        ...prev,
        newDP,
      ]
    })
  }

  const onConfirmConnection = (connection:DataProvider) => {
    setConnections(prev => {
      const updated = prev
        .map(dp => {
          if (dp.getKey()===connection.getKey()) {
            dp.active = true
          }
          return dp
        })
      return updated
    })
  }

  const onRemoveConnection = (connection:DataProvider) => {
    const _onComplete = () => {
      setConnections(prev => prev.filter(p => p.getKey()!==connection.getKey()))
    }
    if (demoMode) {
      _onComplete()
    } else {
      api.store.removeIntegration(connection.getItem(), app)
        .then(item => {
          // TODO set store?
          console.log('successfully removed integration', item)
          _onComplete()
        })
        .catch(e => {
          console.log('TODO handle error', e)
        })
    }
  }

  const onSelectAdAccount = (connection:DataProvider, accountID:string) => {
    const curDP = connections.find(dp => dp.getKey()===connection.getKey())
    if (!curDP) {
      console.log('Programming error: cant find matching connection', connections.map(c => c.getKey()), connection.getKey())
      return
    }

    const auth = curDP.auth?.accounts?.find(a => a.id===accountID)
    if (auth) {
      curDP.accountID = accountID
      curDP.account = auth.name
    } else {
      console.log('Programming error: accountID %s doesnt exist on auth for %s', accountID, connection.service, curDP.auth?.accounts)
    }

    const _onComplete = () => {
      setConnections(prev => prev.map(dp => {
        if (dp.getKey()!==connection.getKey()) return dp
        return curDP
      }))
    }

    if (demoMode) {
      _onComplete()
    } else {
      api.store.updateIntegration(curDP.getItem(), app)
        .then(item => {
          // TODO set store?
          console.log('successfully updated integration')
          _onComplete()
        })
        .catch(e => {
          console.log('TODO handle error', e)
        })
    }

    // // set updated store to settingHistory
    // setSettingsHistory(prev => [
    //   ...prev,
    //   {
    //     desc: `Selected ${integration.service} Account`,
    //     store: _store,
    //     ts: gUnixSec(),
    //   },
    // ])
    // setToast({
    //   active: true,
    //   message: `${acct.name} has been selected`,
    // })
  }

  return (
    <Div className='bg-white py-16 h-full'
      smScreen='pt-0 py-0'
    >
      <div className='z-10'>
        <SettingsNav />
      </div>
      <div className='px-4 sm:px-12 sm:pb-20 md:px-16 xl:max-w-screen-lg 2xl:mx-auto z-0'>
        <Div iff={bannerActive?.active}>
          <BannerCustom />
        </Div>
        <h2 className='lg:text-3xl sm:pt-10 lg:pt-20 font-semibold'>
          {tr('Settings.accountConnect')}
        </h2>
        <AddIntegration onAddIntegration={onAddIntegration} connections={connections} />
        {connections.map((connection) => (
          <ConnectIntegration
            key={connection.getKey()}
            connection={connection}
            onConfirmConnection={onConfirmConnection}
            onRemoveConnection={onRemoveConnection}
            onSelectAdAccount={onSelectAdAccount}
          >
            <IntegrationButton store={store} integration={connection.service} />
          </ConnectIntegration>
        ))}
      </div>
      <Div iff={toast?.active}>
        <Toast content={toast?.message}
          onDismiss={() => setToast({message: '', active: false})} />
      </Div>
    </Div>
  )
}

export default SettingsScreen

const DEMO_AUTHS = [{
  username: 'demo',
  service:  Integration.FACEBOOK,
  created:   1,
  updated:   1,
  accounts: [{
    id: 'FB01',
    name: '東京都',
    status: 'ACTIVE',
  },{
    id: 'FB02',
    name: '全国',
    status: 'ACTIVE',
  },{
    id: 'FB123',
    name: 'Demo Facebook Account',
    status: 'ACTIVE',
  }],
},
// {
//   username: 'demo',
//   service:  Integration.GOOGLE,
//   created:   1,
//   updated:   1,
//   accounts: [{
//     id: 'G01',
//     name: '東京都',
//     status: 'ACTIVE',
//   },{
//     id: 'G02',
//     name: '全国',
//     status: 'ACTIVE',
//   },{
//     id: 'G12345',
//     name: 'Demo Google Ads Account',
//     status: 'ACTIVE',
//   }],
// },
{
  username: 'demo',
  service:  Integration.YJP,
  created:   1,
  updated:   1,
  accounts: [{
    id: 'YJP01',
    name: '東京都',
    status: 'ACTIVE',
  },{
    id: 'YJP02',
    name: '全国',
    status: 'ACTIVE',
  },{
    id: 'YJP123',
    name: 'Demo YJP Account',
    status: 'ACTIVE',
  }],
},{
  username: 'demo',
  service:  Integration.GA,
  created:   1,
  updated:   1,
  accounts: [{
    id: 'UA-01',
    name: '東京都',
    status: 'ACTIVE',
  },{
    id: 'UA-02',
    name: '全国',
    status: 'ACTIVE',
  },{
    id: 'UA-12345',
    name: 'Demo GA Account',
    status: 'ACTIVE',
  }],
}]
