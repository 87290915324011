import React from 'react'
import { useRecoilValue } from 'recoil'

import * as atoms from 'lib/atoms'
import useLang from 'lib/hooks/useLang'
import useDashboardData from 'lib/hooks/useDashboardData'
import TopCard from './TopCard'
import DataCard from '../DataCard'
import TreeMap from '../Charts/TreeMap'
import { formatPercent, numberWithCommas } from 'lib/method/formatNumber'
import { Spinner, Tooltip } from '@shopify/polaris'
import { Div } from 'components/ui'
import icon from 'images/dashboard/grayShopify.svg'

const TopSatisfactiondGroup = () => {
  const loadingData = useRecoilValue(atoms.loadingData)
  const { translate, setLang, lang } = useLang()
  const returningRate = translate('Marketing.returningOrderRate')
  const reOrders = translate('Marketing.reOrders')
  const orders = translate('Marketing.orders')

  const {
    shopifyLoadingProgress,
    satisfactionData,
    calcCustomerBreakdown,
    calcCustomerChangeBreakdown,
  } = useDashboardData()

  const getContent = (data:any) => {
    const def = `${returningRate} = ${reOrders} / ${orders}`
    const desc = `${numberWithCommas(data.rateNum)} / ${numberWithCommas(data.rateDen)} = ${formatPercent(data.rate*100)}`
    const chDesc = `${numberWithCommas(data.last.rateNum)} / ${numberWithCommas(data.last.rateDen)} = ${formatPercent(data.last.rate*100)}`
    return (<div>
      <p><b>{def}</b></p>
      <hr className='mt-2' />
      <p><b>This Period:</b> {desc}</p>
      <hr />
      <p><b>Last Period:</b> {chDesc}</p>
    </div>)
  }
  // const getContent = () => {
  //   return `${repeatToNew} => ${reOrders} / ${orders} * 100 => ${shopifyTotal==null ? 0 : shopifyTotal.orders.returning} / ${shopifyTotal==null ? 0 : shopifyTotal.orders.returning + shopifyTotal.orders.new} * 100 = ${formatPercent(gSatisfactionRate())}`
  // }

  return (
    <div className='cardTop'>
      <div className='topCardStyle rounded-t-2xl pb-3'>
        <TopCard
          loadProgress={shopifyLoadingProgress()}
          metric='Satisfaction'
          value={satisfactionData.value}
          changeValue={satisfactionData.changePercent.value}
          dataPercentage={formatPercent(satisfactionData.changePercent.value*100)}
        />
      </div>
      <div className='lg:w-full 2xl:w-auto'>
        <Tooltip content={getContent(satisfactionData)}>
          <DataCard
            dataTitle={returningRate}
            changeValue={satisfactionData.change.rate}
            positivity={true}
            dataNumber={formatPercent(satisfactionData.rate*100, 2)}
            dataPercentage={formatPercent(satisfactionData.change.rate*100)}
          />
        </Tooltip>
      </div>
      <div className='topCardStyle pt-7 pb-4'>
        <TreeMap data={calcCustomerBreakdown()} metric='Satisfaction' />
      </div>
      { shopifyLoadingProgress() < 100
        ? (
          <Div className='flex justify-center items-center py-24'>
            <Spinner accessibilityLabel='shopify data is loading...' size='small' />
          </Div>
        )
        : calcCustomerChangeBreakdown().map(ch => (
          <DataCard
            key={ch.id}
            loading={false}
            dataTitle={ch.id}
            changeValue={ch.change}
            dataNumber={formatPercent(ch.value)}
            dataPercentage={formatPercent(ch.change)}
            publisherLogo={icon}
          />
        ))}
    </div>
  )
}

export default TopSatisfactiondGroup
