import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Lang } from '@sh/enums'
import { DataProvider, Store } from '@sh/models/classes'
import { Integration } from '@sh/enums'
import { gUnixSec } from '@1f8/commons/date'


import * as atoms from 'lib/atoms'
import useLang from 'lib/hooks/useLang'
import { Div } from 'components/ui'
import { loremIpsum, loremIpsumJA } from 'lib/const/placeholderText'
import aidasModel from '../../images/welcome/aidas_model.svg'
import graphEx from '../../images/welcome/graph_ex.svg'
import WelcomeTextBlock from 'components/app/welcome/WelcomeTextBlock'
import WelcomeNav from 'components/app/welcome/WelcomeNav'
import TutorialVideos from 'components/app/welcome/TutorialVideos'
import WelcomeTopImage from 'components/app/welcome/WelcomeTopImage'


/** Main App Component: Displays to user their AIDAS metrics */
const WelcomeScreen = ({ demoMode=false }) => {
  const { translate } = useLang()

  const tutorials = translate('Common.tutorials')
  const aboutAidas = translate('Welcome.aboutAidas')
  const aidasDesc = translate('Welcome.aidasDesc')
  const roas = translate('Welcome.roas')

  const store = useRecoilValue(atoms.store)
  const [settingsHistory, setSettingsHistory] = useRecoilState(atoms.settingsHistory)

  useEffect(() => {
    const _store = new Store(store.getItem())
    // assign new empty integration
    // const sis = [...store.integrations]
    if (!_store.integrations.find(si => si.service===Integration.FACEBOOK)) _store.integrations.push(new DataProvider({
      service: Integration.FACEBOOK,
      account: '',
      username: '',
      accountID: '',
    }))
    if (!_store.integrations.find(si => si.service===Integration.GOOGLE)) _store.integrations.push(new DataProvider({
      service: Integration.GOOGLE,
      account: '',
      username: '',
      accountID: '',
    }))
    if (!_store.integrations.find(si => si.service===Integration.GA)) _store.integrations.push(new DataProvider({
      service: Integration.GA,
      account: '',
      username: '',
      accountID: '',
    }))
    if (!_store.integrations.find(si => si.service===Integration.YJP)) _store.integrations.push(new DataProvider({
      service: Integration.YJP,
      account: '',
      username: '',
      accountID: '',
    }))
    setSettingsHistory(prev => [
      ...prev,
      {
        desc: 'Adding integration if not exists',
        store: _store,
        ts: gUnixSec(),
      },
    ])
  // only run once on load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Div className='w-full h-full flex justify-center bg-white'>
      <Div className='max-w-screen-2xl relative 2xl:max-w-none'>
        <div className='md:pb-20 sm:pt-6 2xl:px-32'>
          <WelcomeNav />
        </div>
        <div className='px-6 pt-14 sm:px-12 md:px-16 md:w-1/2 md:pt-10 2xl:px-48'>
          <WelcomeTextBlock demoMode={demoMode} />
        </div>
        <WelcomeTopImage />
        <div className='px-6 mt-16 text-center pb-12 md:px-28 md:pt-20 xl:pt-12 2xl:pt-0 xl:pb-0 2xl:pb-10 xl:px-72 2xl:text-3xl'>
          <div className='px-[8%] mb-10 text-center'>
            <p className='italic xl:pt-6'>
              {translate('Welcome.aidasQuote')}
            </p>
            <p className='pt-1 text-lg'>
              - {translate('Welcome.aidasQuoteAuthor')}
            </p>
          </div>
          <b className='text-blue-attention'>{translate('Welcome.aidasP1A')}</b>
          {' '}
          {translate('Welcome.aidasP1B')}
          {' '}
          {translate('Welcome.aidasP1C')}
          <div className='py-6'></div>
          <b className='text-orange-interest'>{translate('Welcome.aidasP1D')}</b>
          {' '}
          {translate('Welcome.aidasP1F')}
          {' '}
          {translate('Welcome.aidasP1E')}
        </div>
        <div className='mt-14 2xl:pr-12'>
          <div className='px-6 pt-14 text-right md:px-12 md:pt-28 xl:px-28 sm:pr-10'>
            <h2 className='text-5xl font-bold'>{tutorials}</h2>
            <p className='pt-4 lg:text-3xl xl:text-4xl xl:pt-6'>TO BE SUCCESSFUL</p>
            <p className='lg:text-3xl xl:text-4xl xl:pt-3'>FIRST LEARN THE AIDAS SYSTEM</p>
          </div>
          <p className='px-6 mt-24 mb-10 text-right pb-24 md:pr-10 md:pl-44 xl:px-28 xl:pl-127 2xl:text-3xl'>
            <b className='text-green-desire'>{translate('Welcome.aidasP2A')}</b>
            {' '}
            {translate('Welcome.aidasP2B')}
            {' '}
            {translate('Welcome.aidasP2C')}
            {' '}
            <b className='text-red-action'>{translate('Welcome.aidasP2D')}</b>
            {' '}
            {translate('Welcome.aidasP2E')}
            {' '}
            <b className='text-purple-satisfaction'>{translate('Welcome.aidasP2F')}</b>
          </p>
        </div>
        <div className='bg-welcomeBg2 md:mx-10 xl:mx-24 2xl:mx-40 bg-center bg-cover rounded-3xl'>
          <div className='text-center pt-10 pb-16 lg:pt-20'>
            <h2 className='pb-6 text-4xl'>AIDAS</h2>
            <p className='px-8 2xl:text-3xl'>{aboutAidas}</p>
          </div>
          <div className='flex flex-col md:flex-row md:items-center md:pb-10 xl:justify-center'>
            <img className='px-6 md:px-16 xl:w-[37%] 2xl:w-[33%]' src={aidasModel} alt='This is a funnel to explain AIDAS model' width={335} height={339} />
            <div className='text-center py-10 md:text-left xl:w-2/5'>
              <h3 className='font-bold pb-10 text-3xl 2xl:text-5xl'>
                {translate('Welcome.whatAidas', {
                  spanA: <span className='text-blue-attention'>A</span>,
                  spanI: <span className='text-orange-interest'>I</span>,
                  spanD: <span className='text-green-desire'>D</span>,
                  spanAc: <span className='text-red-action'>A</span>,
                  spanS: <span className='text-purple-satisfaction'>S</span>,
                })}
              </h3>
              <p className='px-6 md:px-0 md:pr-20 2xl:text-3xl'>{aidasDesc}</p>
            </div>
          </div>
          <div className='text-center py-10'>
            <h3 className='font-bold pb-10 text-3xl 2xl:text-5xl'>ROAS</h3>
            <p className='px-10 2xl:text-3xl'>{roas}</p>
          </div>
          <TutorialVideos />
        </div>
        <div className='h-28 md:h-40'></div>
        <div className='bg-welcomeBg3 pb-8 flex flex-col sm:flex-row items-center justify-center sm:pt-16 xl:justify-center'>
          <div className='px-6 pt-14 pb-12 md:pl-12 md:w-1/2 md:pt-0 xl:w-[37%]'>
            <WelcomeTextBlock demoMode={demoMode} />
          </div>
          <img src={graphEx} alt='aidas app dashboard graph'
            width='335' height='177'
            className='px-2 md:px-0' />
        </div>
      </Div>
    </Div>
  )
}

export default WelcomeScreen
