const FB_REDIRECT = 'https://apis-aidas.1f8.dev/code/facebook'
const FB_SCOPES = [
  'business_management',
  'email',
  'ads_read',
  'public_profile',
].join(',')

const GA_REDIRECT = 'https://apis-aidas.1f8.dev/code/ga'
const GA_SCOPES = [
  'email',
  'profile',
  'openid',
  'https://www.googleapis.com/auth/analytics',
  'https://www.googleapis.com/auth/analytics.readonly',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
].join(' ')

const GOOGLE_REDIRECT = 'https://apis-aidas.1f8.dev/code/google'
const GOOGLE_SCOPES = [
  'email',
  'profile',
  'openid',
  'https://www.googleapis.com/auth/adwords',
].join(' ')

const GOOGLE_BASE_QS = [
  'client_id=351631067909-220r6n6lu0vdvi9a9qm07ffl5fqauo4l.apps.googleusercontent.com',
  'response_type=code',
  'access_type=offline',
  'prompt=consent',
].join('&')

const YJP_REDIRECT = 'https://apis-aidas.1f8.dev/code/yjp'
const YJP_SCOPES = 'yahooads'

const oauthURLs = {
  fb: `https://www.facebook.com/v14.0/dialog/oauth?client_id=332839972042101&redirect_uri=${FB_REDIRECT}&scope=${FB_SCOPES}`,
  // fb: `https://www.facebook.com/v14.0/dialog/oauth?client_id=332839972042101&redirect_uri=${FB_REDIRECT}&state=test`,
  google: `https://accounts.google.com/o/oauth2/v2/auth?${GOOGLE_BASE_QS}&redirect_uri=${GOOGLE_REDIRECT}&scope=${GOOGLE_SCOPES}`,
  ga: `https://accounts.google.com/o/oauth2/v2/auth?${GOOGLE_BASE_QS}&redirect_uri=${GA_REDIRECT}&scope=${GA_SCOPES}`,
  yjp: `https://biz-oauth.yahoo.co.jp/oauth/v1/authorize?&response_type=code&client_id=8c8de15ca0ef68754a145a12601ed05e4b4aeb65959f5c5fe60cfa11d45f23a7&redirect_uri=${YJP_REDIRECT}&scope=${YJP_SCOPES}`,
}

export {
  oauthURLs,
  FB_SCOPES,
}
