import React from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { useRecoilValue } from 'recoil'
import * as atoms from 'lib/atoms'

const useRemoteRedirect = () => {

  const app = useRecoilValue(atoms.clientAppSelector)

  const remoteRedirect = (url) => {
    console.log('redirecting to ', url)
    Redirect.create(app).dispatch(Redirect.Action.REMOTE, url)
  }

  return { remoteRedirect, app }
}

export default useRemoteRedirect